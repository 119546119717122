import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { TabBar } from "@/components/Layouts"
import { renderRoutes } from 'react-router-config'
import './index.less'

const Index = (props) => {

  return (
    <div className='main'>
        <TabBar />
        {renderRoutes(props.route.routes)}
    </div>
  )
}



const mapState = state => {
  return {
    count: state.count
  };
};

export default connect(mapState)(Index)