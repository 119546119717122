import ClientAxios from '../utils/clientAxios';
import EventService from './eventService';
let proxy = {
    api: process.env.NODE_ENV === "development" ? '': process.env.REACT_APP_PROXY,
};

let client = {
    api:new ClientAxios.ClientAxios(proxy.api)
}

let eventService = new EventService.EventService(client.api);
export {
    eventService
}

