import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import intl from "react-intl-universal"
import { PullToRefresh, Button, InfiniteScroll, Toast, DotLoading } from "antd-mobile"
import appActions from "@/store/modules/app";
import Loading from "@/components/Layouts/Loading"
import Select from '@/components/select'
import { eventService } from "@/service"
import { BasicsTopic, BattleTopic, VoteTopic } from "@/components/TopicTemplate"
import Sidebar from '@/components/Sidebar'
import MinaShare from "@/components/MinaShare"
import { isMina } from '@/utils/index'
import { loginUrlRedirect } from '@/utils/auth'
import "./index.less"

const InfiniteScrollContent = ({ hasMore }) => {
  return (
    <>
      {hasMore ? (
        <>
          <span>Loading</span>
          <DotLoading />
        </>
      ) : (
        <span>--- {intl.get('InfiniteScroll_nodata')} ---</span>
      )}
    </>
  )
}

const Topic = (props) => {

  let params = {
    per_page:10, //每页条数
    page:1, //当前页
    is_recom:false, // 是否是推荐
  }
  const [detail,setDetail] = useState(null);
  const [list,setList] = useState([]);
  const [hasMore,setHasMore] = useState(true);
  const [topicCatgeList,setTopicCatgeList] = useState([]);
  const [topicTypeList,setTopicTypeList] = useState([]);

  useEffect(() => {
    window.scrollTo(0,0)
    getData();
    // 获取分类
    eventService.getTopicCateList().then(res=>{
      if(res.status){
        setTopicCatgeList(res.data)
      }
    })
    // //获取类型
    // eventService.getTopicTypeList().then(res=>{
    //   if(res.status){
    //     res.data.map(item=>{
    //       item.cate_name = item.type_name
    //     })
    //     setTopicTypeList(res.data)
    //   }
    // })
  }, [])

  const toCreate = ()=>{
    if(!props.islogin){
      loginUrlRedirect()
      return 
    }
    props.history.push('/createTopic')
  }


  const getData = async (showloading)=>{
    if(showloading){
      Toast.show({
        icon: 'loading',
        duration:0,
        maskClickable:false
      })
    }
    
    let {data,status} =  await eventService.getTopic(params)
    Toast.clear()
    if(status){
      let arr = list.concat(data.data);
      if(params.page === 1){
        arr = data.data;
      }
      if(arr.length * params.page >= data.total){
        setHasMore(false)
      }
      setList(arr);
      setDetail(data);
    }
  }

  const pageNext =  ()=>{
    params.page = params.page+1;
    return getData()
  } 

  const toDetail = (item)=>{
    props.history.push('/topic/detail/' + item.id)
  }

  return (
    <div className="topic">
      {!detail ? (
        <Loading />
      ) : (
        <>
          <div className="selectList">
            <Select
              placeholder={intl.get("topic.class")}
              list={topicCatgeList}
              data={props.select.class}
              setData={(data)=>{
                if(data){
                  params.cate_id = data.id;
                }else{
                  params.cate_id = null;
                }
                params.page = 1;
                getData(true);
                props.dispatch(appActions.setSelect({select:{...props.select, class:data}}));
              }}
            />
            {/* <Select
              placeholder={intl.get("topic.topic")}
              list={topicTypeList}
              data={props.select.topic}
              setData={(data)=>{
                if(data){
                  params.type_id = data.id;
                }else{
                  params.type_id = null;
                }
                params.page = 1;
                getData(true);
                props.dispatch(appActions.setSelect({select:{...props.select, topic:data}}));
              }}
            /> */}
            <div style={{marginLeft:"20px"}}></div>
            <Select
              placeholder={intl.get("topic.time")}
              list={[
                {id:'release_time',cate_name:intl.get('topic.time')},
                {id:'view_points_count',cate_name:intl.get('topic.hot')},
              ]}
              data={props.select.time}
              setData={(data)=>{
                if(data){
                  params.sort_field = data.id;
                }else{
                  params.sort_field = null;
                }
                params.page = 1;
                getData(true);
                props.dispatch(appActions.setSelect({select:{...props.select, time:data}}));
                
              }}
            />
          </div>
          <div className="main_content">
            <PullToRefresh
              onRefresh={async () => {
                params.page = 1;
                await getData()
              }}
            >
              {
              list ? list.map((item,index)=>{
                return <div key={index} className='list_item' onClick={()=>{toDetail(item)}}>
                    {item.type_id === 1 && <BasicsTopic type='topic' data={item} />  }
                    {item.type_id === 2 && <BattleTopic type='topic' data={item} />  }
                    {item.type_id === 3 && <VoteTopic type='topic' data={item} />  }
                  </div>
              })
              :
              null
            }
              <InfiniteScroll loadMore={pageNext} hasMore={hasMore} >
                <InfiniteScrollContent hasMore={hasMore}/>
              </InfiniteScroll>
            </PullToRefresh>

            <Button className="topic_footer" onClick={toCreate}> <i className="iconfont">&#xea73;</i> {intl.get('topic.create_topic')}</Button>
          </div>
          {/* {
            isMina() &&
            <Sidebar style={{bottom: 60}}>
              <MinaShare title={intl.get('mina_share.base')}/>
            </Sidebar>
          } */}
        </>
      )}
    </div>
  )
}

const mapState = (state) => {
  return {
    select: state.app.select,
    islogin:state.app.islogin,
  }
}

export default connect(mapState)(Topic)
