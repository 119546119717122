import { isWeixin, ua } from 'ppo'

export const isMina = () => {
  return isWeixin() && ua('l').includes('miniprogram')
}

// 给微信小程序发送消息
export const wxPostMessage = (title, link) => {
  const redirectUrl = localStorage.getItem('redirect_url')
  const loginUrl = localStorage.getItem('login_url')
  wx.miniProgram.postMessage({
    data: {
      type: 'share',
      source: 'topic',
      title: title,
      link:`${link || `${window.location.origin}/home`}?login_url=${encodeURIComponent(loginUrl)}${redirectUrl ? `&redirect_url=${encodeURIComponent(redirectUrl)}` : ''}`,
    }
  })
}

export const gotoMinaShare = (title, topicId, link) => {
  const redirectUrl = localStorage.getItem('redirect_url')
  const path = `${link || `${window.location.origin}/home`}${redirectUrl ? `?redirect_url=${redirectUrl}` : ''}`
  const minaUrl = `/pages/share-poster/index?source=topic&path=${encodeURIComponent(path)}&title=${encodeURIComponent(title)}${topicId ? `&topicId=${topicId}` : ''}`
  console.log('MinaShare -> minaUrl', minaUrl)
  wx.miniProgram.navigateTo({ url: minaUrl})
}