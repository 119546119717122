import React, { useEffect, useState } from "react"
import _ from "underscore"
import intl from "react-intl-universal"
import { Ellipsis,Button } from "antd-mobile"
import { useHistory } from "react-router-dom"
import "./index.less"

const CommentItem = (props) => {
  const data = props.data || {};
  const history = useHistory();
  
  return (
    <div className="commentItem_B" onClick={()=>{
      history.push('/pointDetail/'+data.viewpoint_id)
    }}>
      <div className="title">
        <Ellipsis
          rows={3}
          content={data.content}
        />
      </div>
      {
        data.view_point_info && <div className="topic_text">
        <div className="left">
          <img src={data.view_point_info.belongs_user.avatar} alt="" />
        </div>
        <div className="right">
          <span>{data.view_point_info.belongs_user.nick_name}：</span>{data.view_point_info.content}
        </div>
      </div>
      }
      
      
    </div>
  )
}

export default CommentItem
