import { combineReducers, createStore,applyMiddleware ,compose} from "redux";
import thunkMiddleware from 'redux-thunk'
import rootReducer from './root'


const middlewares = [
  thunkMiddleware
]

const enhancer = compose(
  applyMiddleware(...middlewares),
  // other store enhancers if any
)


let store = null

export default function configStore () {
  store = createStore(rootReducer,enhancer)
  return store
}

export function getStore() {
  return store
}
