import React, { useState, useEffect, createRef } from "react"
import intl from "react-intl-universal"
import _ from 'underscore'
import { connect } from "react-redux"
import { PullToRefresh, Button, Skeleton } from "antd-mobile"
import Loading from "@/components/Layouts/Loading"
import { eventService } from "@/service"
import "./index.less"

const Select = (props) => {
  const [isSelect, setSelect] = useState(false);
  const ref = createRef()
  useEffect(() => {
      if(isSelect){
          ref.current.focus()
      }
  }, [isSelect])

  return (
    <div
      className={isSelect ? "select_Comp active" : "select_Comp"}
      onClick={(e) => {
          if(e.target.className.indexOf('clear') !== -1){
              return
          }
        setSelect(!isSelect)
      }}
    >
      <p>
        { !_.isEmpty(props.data) ? <span style={{color:'#333333'}}>{props.data.cate_name}</span> : props.placeholder}
        {
            props.data ? <i className="iconfont clear" onClick={()=>{
                            props.setData(null)
                        }}>&#xe601;</i>
             :<i className="iconfont down">&#xe69b;</i>
        }
         
      </p>
      <ul>
        {props.list &&
          props.list.map((item) => {
            return <li
                    className={item.id === props.data?.id ? 'li_active' : ''}
                    key={item.id}
                    onClick={()=>{
                        props.setData(item)
                    }}
                >
                    {item.cate_name}
                </li>
          })}
      </ul>
      <input readOnly ref={ref} type="text" className="input_select" onBlur={()=>{
          setTimeout(()=>{
            setSelect(false)
          },100)
          
      }} />
    </div>
  )
}

const mapState = (state) => {
  return {
    select: state.app.select,
  }
}

export default connect(mapState)(Select)
