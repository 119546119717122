import React, { useState, useEffect } from 'react';

const style = {
  img: {
    width: '100%',
    height: '100%'
  }
}


const Connection = ()=>{

  const [list, setList] = useState([]);

  useEffect(() => {


  }, []);

  return <div className="swiper-container">
    <div className="swiper-wrapper">
      {
        list.map(item => {
          return (
            <div className="swiper-slide" key={item.imdbID}>
              <img src={item.Poster} style={style.img}></img>
            </div>
          )
        })
      }
    </div>
  </div>
}

export default Connection;