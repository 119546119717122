import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import intl from "react-intl-universal"
import _ from "underscore"
import { PullToRefresh, Button, InfiniteScroll, Tabs, Popup, Toast,Empty,TextArea } from "antd-mobile"
import appActions from "@/store/modules/app"
import Loading from "@/components/Layouts/Loading"
import CommentItem from "@/components/CommentItem"
import CollectItem from "@/components/CollectItem"
import VoteItem from "@/components/VoteItem"
import Sidebar from '@/components/Sidebar'
import MinaShare from "@/components/MinaShare"
import { eventService } from "@/service"
import { BasicsTopic, BattleTopic, VoteTopic } from "@/components/TopicTemplate"
import { BaseNavBar } from "@/components/Layouts"
import { isMina, wxPostMessage } from '@/utils/index'
import VIP from "@/static/image/vip.png"
import sandian_icon from "@/static/image/sandainicon.png"
import { loginUrlRedirect } from '@/utils/auth'
import pk_03 from "@/static/image/pk_03.png"
import "./index.less"

const TopicDetail = (props) => {
  const [detail, setDetail] = useState(null)
  const [add_one, setAdd_one] = useState(0)
  const [tabIndex, setTabIndex] = useState(0)
  const [topic_visible, setTopic_visible] = useState(false)
  const [selectIndex,setSelectIndex] = useState(-1); // 选择投票的数据id
  const [viewpoint,setViewpoint] = useState([]);
  const [favoriteUserList,setFavoriteUserList] = useState([]);
  const [userListByTopicList,setUserListByTopicList] = useState([]);
  const [topic_success,setTopic_success] =useState(false)
  const [textareaValue,setTextareaValue] = useState('')

  useEffect(() => {
    window.scrollTo(0,0)
    getData();
  }, [])

  const getTopicPoint = async ()=>{
    let id = props.match.params.id;
    eventService.getViewpoint({
      topic_id:id,
      page:1,
      per_page:1000
    }).then(res=>{
      Toast.clear()
      if(res && res.status){
        setViewpoint(res.data.data)
      }
    })
  }

  const getVoteUserListByTopicId = async ()=>{ // 获取投票数据
    eventService.getVoteUserListByTopicId({
      topic_id:props.match.params.id,
      page:1,
      per_page:1000
    }).then(res=>{
      if(res && res.status){
        setUserListByTopicList(res.data.data)
      }
    })
  }


  const getData = async (isLoading,is_vote) => {
    let id = props.match.params.id;
    let params = null;
    if(!isLoading){
      params = {
        is_inc:true
      }
    }
    Promise.all([
      await eventService.getTopicDetail(id,params), // 获取详情数据
      await eventService.getViewpoint({ // 获取观点数据
        topic_id:id,
        page:1,
        per_page:1000
      }),
      await eventService.favoriteUserList({ // 获取收藏数据
        topic_id:id,
        page:1,
        per_page:1000
      })
    ]).then(res=>{
      Toast.clear();
      if(is_vote){
        setTopic_success(true);
      }
      if(isLoading){
        Toast.show({
          icon:'success',
          content:intl.get('success')
        })
      }
      setTimeout(() => {
        setAdd_one(1)
        setTimeout(() => {
          setAdd_one(2)
        })
      }, 1000)
      if(res[0] && res[0].status){
        setDetail(res[0].data);
        isMina() &&  wxPostMessage(res[0].data.topic_name, window.location.href)
        if(res[0].data.type_id !== 1){
          getVoteUserListByTopicId()
        }
      }
      if(res[1] && res[1].status){
        setViewpoint(res[1].data.data)
      }
      if(res[2] && res[2].status){
        setFavoriteUserList(res[2].data.data)
      }
    })
  }

  // 投票
  const topicVote = async ()=>{
    Toast.show({
      icon:'loading',
      maskClickable:false,
      duration:0
    })
    let {status,message} = await eventService.topicVote({
      topic_id:props.match.params.id,
      topic_vote_id:detail.vote_list[selectIndex].id
    })
    if(status){
      setTopic_visible(false);
      getData(true,true);
    }else{
      setTopic_visible(false);
      Toast.show({
        icon:'fail',
        content:message
      })
    }
  }

  // 收藏、取消收藏
  const favoriteTopic = async (operation)=>{
    if(!props.islogin){
      loginUrlRedirect()
      return 
    }
    Toast.show({
      icon:'loading',
      maskClickable:false,
      duration:0
    })
    let {status,message} = await eventService.favoriteTopic({
      operation,
      topic_id:props.match.params.id
    })
    if(status){
      getData(true);
    }else{
      Toast.show({
        icon:'fail',
        content:message
      })
    }
  }
  //发表观点
  const saveViewpoint = ()=>{
    if(!textareaValue){
      Toast.show({
        icon:"fail",
        content:intl.get('topic_detail_error')
      })
      return false
    }
    Toast.show({
      icon:"loading",
      duration:0
    })
    let params={
      content:textareaValue,
      topic_id:props.match.params.id
    }
    eventService.viewpoint(params).then(res=>{
      if(res && res.status){
        getData(true)
        setTopic_success(false)
      }else{
        Toast.show({
          icon:"fail",
          content:res.message
        })
      }
    })
  }

  return (
    <div className="topicDetail">
      {!detail ? (
        <Loading />
      ) : (
        <>
          <BaseNavBar title={intl.get("topic_detail")} />
          <div className="header">
            <div className="author">
              {
                !_.isEmpty(detail.belongs_user) && <p className="img">
                  <img
                    className="headimg"
                    src={detail.belongs_user.avatar}
                    alt=""
                  />
                  {
                    detail.belongs_user.is_official && <img className="vip" src={VIP} alt="" />
                  }
                </p>
              }
              
              <div className="right">
                {
                  !_.isEmpty(detail.belongs_user) && <div className="name">
                  {detail.belongs_user.nick_name}
                  {!_.isEmpty(detail.belongs_user.type) && detail.belongs_user.type.type ===0 && <span className="author_tag success">{detail.belongs_user.type.type_name}</span>}
                  {!_.isEmpty(detail.belongs_user.type) && detail.belongs_user.type.type ===1 && <span className="author_tag warn">{detail.belongs_user.type.type_name}</span>}
                  {!_.isEmpty(detail.belongs_user.type) && detail.belongs_user.type.type ===4 && <span className="author_tag error">{detail.belongs_user.type.type_name}</span>}
                  {!_.isEmpty(detail.belongs_user.type) && detail.belongs_user.type.type ===5 && <span className="author_tag processing">{detail.belongs_user.type.type_name}</span>}
                  {!_.isEmpty(detail.belongs_user.tntm_user_info) && detail.belongs_user.tntm_user_info.online_status === false && <span className="author_tag purple">{intl.get('userType')}</span>}
                  {!_.isEmpty(detail.belongs_user.tntm_user_info) && detail.belongs_user.tntm_user_info.online_status === true && localStorage.getItem('market_id') === '36' && <span className="author_tag remote">{intl.get('remote')}</span>}
                  {
                    detail.belongs_user.is_official && <p>{intl.get("official")}</p>
                  }
                </div>
                }
                
                <div className="time">{detail.time_tran}</div>
              </div>
            </div>
            <div className="title">{detail.topic_name}</div>
            <div className="desc">{detail.desc}</div>
          </div>
          {detail.type_id !== 1 && !topic_success && (
            <div className="topic_info">
              {!_.isEmpty(detail.vote_list) && (detail.vote_list[0].ratio !==0 || detail.vote_list[1].ratio !==0) && detail.type_id === 2 && (
                <div className="pk">
                  <div className="num">{detail.vote_list[0].ratio + "%"}</div>
                  <div className="line_box">
                    <div
                      className={detail.vote_list[0].ratio !==0 && detail.vote_list[0].ratio !==100 ? 'line' : 'line borderReader'}
                      style={{ width: detail.vote_list[0].ratio + '%' }}
                    >
                      {
                      (detail.vote_list[0].ratio !==0 && detail.vote_list[1].ratio !==0) && <img
                        src={sandian_icon}
                        alt=""
                      />
                    }
                    </div>
                    
                    <div
                      className={detail.vote_list[1].ratio !==0 && detail.vote_list[1].ratio !==100 ? 'line' : 'line borderReader2'}
                      style={{ width: detail.vote_list[1].ratio + '%' }}
                    ></div>
                  </div>
                  <div className="num">{detail.vote_list[1].ratio + "%"}</div>
                </div>
              )}
              {!_.isEmpty(detail.vote_list) && detail.type_id === 2 && (
                <div className="desc">
                  <div>
                    <p className={ (!_.isEmpty(detail.vote_info) && detail.vote_info.id === detail.vote_list[0].id) ? 'active' : ''}>{detail.vote_list[0].support_name}</p>
                  </div>
                  
                  <img src={pk_03} alt="" />
                  <div>
                    <p className={ (!_.isEmpty(detail.vote_info) && detail.vote_info.id === detail.vote_list[1].id) ? 'active' : ''}>{detail.vote_list[1].support_name}</p>
                  </div>
                </div>
              )}

              {!_.isEmpty(detail.vote_list) && detail.type_id === 3 && (
                <div className="list">
                  {detail.vote_list.map((item) => {
                    return (
                      <div className={!_.isEmpty(detail.vote_info) && detail.vote_info.id === item.id ? 'item_box active' :'item_box'} key={item.support_name}>
                        <div className="line_bg" style={{ width: item.ratio + '%' }}>
                          <div></div>
                        </div>
                        <div className="item">
                          <span className="text">{item.support_name}</span>
                          <span className="num">{item.ratio + '%'}</span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}
              <div className='footer'>{detail.person_total} {intl.get('take')} <span>{intl.get('end',{time:detail.end_time})}</span> </div>
            </div>
          )}
          {
            !topic_success && 
            <div className="comment">
              <div className="tabs">
                <Tabs
                  onChange={(val) => {
                    setTabIndex(Number(val))
                  }}
                >
                  <Tabs.Tab title={intl.get("viewpoint") + ' ' + detail.view_points_count} key="0" />
                  <Tabs.Tab title={intl.get("collect") + " " + detail.favorite_count} key="1" />
                  {
                    detail.type_id !== 1 && <Tabs.Tab title={intl.get("vote") + " " + detail.vote_star_count} key="2" />
                  }
                </Tabs>
                <div className="onlooker">
                  {intl.get("onlooker")} <span>{detail.browse_info_count}</span>
                </div>
                {add_one !== 2 && (
                  <div className={!add_one === 0 ? "add_one" : "add_one active"}>
                    +1
                  </div>
                )}
              </div>

              <div className="item">
              {
                tabIndex === 0 && !_.isEmpty(viewpoint) ? 
                viewpoint.map((item,i)=>{
                  return <CommentItem topicType={detail.type_id} vote_list={detail.vote_list} history={props.history}  showHot={ i === 0  ? true : false} key={item.user_id} data={item} resetData={getTopicPoint} />
                })
                :
                tabIndex === 0 && <Empty description={intl.get('noData')} />
              }

              {
                tabIndex === 1 && !_.isEmpty(favoriteUserList) ? 
                favoriteUserList.map(item=>{
                  return <CollectItem key={item.user_id} data={item} />
                })
                :
                tabIndex === 1 && <Empty description={intl.get('noData')} />
              }
              {
                tabIndex === 2 && !_.isEmpty(userListByTopicList) ? 
                userListByTopicList.map(item=>{
                  return <VoteItem key={item.user_id} data={item} />
                })
                :
                tabIndex === 2 && <Empty description={intl.get('noData')} />
              }
                
              </div>
            </div>
          }

          

            {/* 投票成功 */}
            {
              topic_success && 
              <div className="topic_success">
                {
                  detail.type_id !== 1 && <>
                    <p><i className="iconfont">&#xe605;</i> {intl.get('vote_success')}</p>
                    <div className="topic_t">
                      {intl.get('support')}：<span>{!_.isEmpty(detail.vote_info) ? detail.vote_info.support_name : ''}</span>
                    </div>
                  </>
                }
                
                <div className="textarea">
                  <TextArea
                    value={textareaValue}
                    onChange={val => {
                      setTextareaValue(val)
                    }}
                    placeholder={intl.get('topic_opinion')}
                    autoSize={{ minRows: 7, maxRows: 7 }}
                  />
                </div>
              </div>
            }

          
          {
            !topic_success && 
            <div className="topic_detail_footer">
              {/* 收藏/取消收藏 */}
              {
                detail.is_favorite ? <Button onClick={()=>favoriteTopic('unstar')}><i className="iconfont">&#xe8c6;</i>{intl.get('cancel_collect')}</Button>
                : <Button className="nobg" onClick={()=>favoriteTopic('star')}><i className="iconfont">&#xe8b9;</i>{intl.get('collect_topic')}</Button>
              }
              {/* 发表观点/立即投票 */}
              {
                detail.is_published && (detail.type_id === 1 || (detail.type_id !== 1 && detail.is_vote === true)) && <Button onClick={()=>{
                  if(!props.islogin){
                    loginUrlRedirect()
                    return 
                  }
                  setTopic_success(true)
                }}><i className="iconfont">&#xe617;</i>{intl.get("publish")}</Button> // 发表观点
              }
              {/* {
                !detail.is_published && (detail.type_id === 1 || (detail.type_id !== 1 && detail.is_vote === true)) && <Button onClick={()=> props.history.push(`/pointDetail/${detail.view_point_id}`)}><i className="iconfont">&#xe744;</i>{intl.get("myComment")}</Button> 
              } */}
              {
                detail.type_id !== 1 &&  detail.is_vote === false && detail.is_vote_operation && <Button
                    onClick={() => {
                      if(!props.islogin){
                        loginUrlRedirect()
                        return 
                      }
                      setTopic_visible(true)
                    }}
                  >
                    <i className="iconfont">&#xe604;</i>
                    {intl.get("vote_ticket")}
                  </Button>
              }
              {
                detail.type_id !== 1 &&  detail.is_vote === false && detail.is_vote_operation === false && <Button disabled>{intl.get('vote_end')}</Button>
              }
              
            </div>
          }

          {
            topic_success && 
             <div className="topic_detail_footer">
              <Button
                className="nobg"
                onClick={() => {
                  setTopic_success(false)
                }}
              >
                <i className="iconfont">&#xe687;</i>
                {intl.get("skip")}
              </Button>
              <Button
                onClick={saveViewpoint}
              >
                <i className="iconfont">&#xe604;</i>
                {intl.get("ok")}
              </Button>
            </div>
          }

          {/* 投票话题类型弹窗 */}
          <Popup
            visible={topic_visible}
            onMaskClick={() => {
              setTopic_visible(false)
            }}
            bodyStyle={{
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            <div className="popList">
              <div className="title">
                {detail.topic_name}
              </div>
              <div className="desc">
              {detail.person_total} {intl.get('take')} <span>{intl.get('end',{time:detail.end_time})}</span>
              </div>
              {
                !_.isEmpty(detail) && detail.type_id === 3 &&  <div className="list">
                  {
                    !_.isEmpty(detail.vote_list) && detail.vote_list.map((item,index)=>{
                      return <div className={selectIndex === index ? 'item_box active' : 'item_box'}  key={item.support_name} onClick={()=>setSelectIndex(index)}>
                        <div className="line_bg" style={{ width: item.ratio+'%' }}>
                          <div></div>
                        </div>
                        <div className="item">
                          <span className="text">{item.support_name}</span>
                          <span className="num">{intl.get('select_p',{num:item.ratio + '%'})}</span>
                        </div>
                      </div>
                    })
                  }
                </div>
              }
             

              {
                detail.type_id === 2 && <div className="pk">
                <Button 
                  onClick={()=>{setSelectIndex(0)}}
                  className={selectIndex === 0 ? 'select' : ''}
                > { detail.vote_list[0].support_name }  <span>{intl.get('select_p',{num:detail.vote_list[0].ratio + "%"})}</span></Button>
                <Button 
                  onClick={()=>{setSelectIndex(1)}}
                  className={selectIndex === 1 ? 'select' : ''}
                > { detail.vote_list[1].support_name }  <span>{intl.get('select_p',{num:detail.vote_list[1].ratio + "%"})}</span></Button>
              </div>
              }
              <Button 
                className="topic_button" 
                disabled={selectIndex === -1}
                onClick={topicVote}
              >
                <i className="iconfont">&#xe604;</i>
                {intl.get("ok")}
              </Button>
              <p>{intl.get("hint")}</p>
            </div>
          </Popup>
          {/* {
            isMina() &&
            <Sidebar style={{bottom: 60}}>
              <MinaShare
                title={detail.topic_name}
                topicId={detail.id}
                link={window.location.href}/>
            </Sidebar>
          } */}
        </>
      )}
    </div>
  )
}

const mapState = (state) => {
  return {
    select: state.app.select,
    islogin:state.app.islogin
  }
}

export default connect(mapState)(TopicDetail)
