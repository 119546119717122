import React, { useState, useEffect, createRef } from "react"
import intl from "react-intl-universal"
import { connect } from "react-redux"
import { Ellipsis } from "antd-mobile"
import pk_03 from '@/static/image/pk_03.png'
import hot from '@/static/image/hot.png'
import "./index.less"

const CollectItem = (props) => {
  const {data} = props;
  return (
    <div className="collectItem">
      <div>
      <img className="head_img" src={data.belongs_user.avatar} alt="" />
      <p className="name">{data.belongs_user.nick_name}</p>
      </div>
    </div>
  )
}


export default CollectItem
