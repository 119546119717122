import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal'
import ppo from 'ppo'
import { Link, useHistory} from "react-router-dom";
import "./TabBar.less";

function TabBar(props) {
  let history = useHistory()
  const [index,setIndex] = useState(null);
  const [routerList,setRouterList] = useState([
    '/home','/topic','/me'
  ])
  const [list,setList] = useState([
    { url:'/home',name:intl.get('tabbar.recommend') },
    { url:'/topic',name:intl.get('tabbar.topic') },
    // { url:'/index/connection',name:intl.get('tabbar.connection') },
  ])

  useEffect(()=>{
    if(localStorage.getItem("redirect_url")){
      setList([...list,{ url:'/me',name:intl.get('tabbar.myTopic') }])
    }else{
      setList([...list,{ url:'/me',name:intl.get('tabbar.me') }])
    }
    console.log(routerList.includes(history.location.pathname));
    let pathname = history.location.pathname;
    if(pathname ==='/home'){
      setIndex(0)
    }else if(pathname ==='/topic'){
      setIndex(1)
    }else if(pathname ==='/connection'){
      setIndex(2)
    }else if(pathname ==='/me'){
      setIndex(2)
    }
  },[])

  useEffect(()=>{
    if(history.location.pathname === '/home'){
      setIndex(0)
    }else if(history.location.pathname === '/topic'){
      setIndex(1)
    }else if(history.location.pathname === '/me'){
      setIndex(2)
    }
  },[history.location])

  return (
    <>
      {routerList.includes(history.location.pathname) ? (
        <div className="tabbar">
          {localStorage.getItem("redirect_url") && (
            <p
              className="back_REDIRECT"
              onClick={() => {
                let url = localStorage.getItem("redirect_url")
                location.href = url
              }}
            >
              <i className="iconfont">&#xe609;</i>
            </p>
          )}
          {list.map((item, i) => {
            return (
              <Link
                key={i}
                onClick={() => setIndex(i)}
                className={i === index ? "tabbar-item action" : "tabbar-item"}
                to={item.url}
              >
                {item.name}
              </Link>
            )
          })}
        </div>
      ) : null}
    </>
  )
}

export default TabBar;