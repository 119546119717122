import React, { useEffect, useState } from "react"
import _ from "underscore"
import intl from "react-intl-universal"
import { Ellipsis,Button,Toast } from "antd-mobile"
import { eventService } from "@/service"
import { useHistory } from "react-router-dom"
import "./index.less"

const CollectItem = (props) => {
  const data = props.data || {};
  const history = useHistory();

    // 收藏、取消收藏
    const favoriteTopic = async (e)=>{
      e.stopPropagation()
      Toast.show({
        icon:'loading',
        maskClickable:false,
        duration:0
      })
      let {status,message} = await eventService.favoriteTopic({
        operation:'unstar',
        topic_id:data.id
      })
      if(status){
        props.resetData();
      }else{
        Toast.show({
          icon:'fail',
          content:message
        })
      }
    }


  return (
    <div className="collectItemL" onClick={()=>{
      history.push('/topic/detail/'+ data.id)
    }}>
      <div className="title">{data.topic_name}</div>
      <div className="collectItem_author_list">
        <div className="author_list_item">
          <span className="hot">
            {data.view_points_count} {intl.get("viewpoint")}
          </span>
          <span className="hot">
            {data.favorite_count} {intl.get("collect")}
          </span>
          <span className="hot">
           {data.browse_info_count} {intl.get("onlooker")}
          </span>
        </div>
        <div className="topicItem_footer">
            <Button onClick={favoriteTopic}> <i className="iconfont">&#xe8b9;</i> {intl.get('cancel_collect')}</Button>
        </div>
      </div>
    </div>
  )
}

export default CollectItem
