import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Button, Tabs, Empty, Toast } from "antd-mobile"
import intl from "react-intl-universal"
import ppo from 'ppo';
import { BaseNavBar } from "@/components/Layouts"
import create from "@/static/image/create.png"
import setting02_03 from "@/static/image/setting02_03.png"
import setting_left from "@/static/image/setting_left.png"
import TopicItem from "./topic"
import ViewpointItem from "./viewpoint"
import CommentItem from "./comment"
import CollectItem from "./collect"
import { eventService } from "@/service"
import Loading from "@/components/Layouts/Loading"
import "./index.less"

let pages = {
  page:1,
  per_page:10000
}

const CommuityData = (props) => {
  const [tabIndex, setTabIndex] = useState(-1);
  const [detail, setDetail] = useState(null);
  const [topicItem, setTopicItem] = useState([]);
  const [viewpointItem, setViewpointItem] = useState([]);
  const [commentItem, setCommentItem] = useState([]);
  const [collectItem, setCollectItem] = useState([]);

  useEffect(()=>{
    if(ppo.getUrlParam('index')){
      let index = Number(ppo.getUrlParam('index'))
      setTabIndex(index)
    }else{
      setTabIndex(0)
    }
    getData()
  },[])

  useEffect(()=>{
    if(tabIndex === 0){
      if(topicItem.length === 0){
        getTopics()
      }
    }
    if(tabIndex === 1){
      if(viewpointItem.length === 0){
        getViewPoints()
      }
    }
    if(tabIndex === 2){
      if(commentItem.length === 0){
        getComments()
      }
    }
    if(tabIndex === 3){
      if(collectItem.length === 0){
        getFavoriteList()
      }
    }
  },[tabIndex])

  const getData = () =>{
    eventService.mySpaceUser().then(res=>{
      if(res && res.status){
        setDetail(res.data)
      }
    })
  }

  const getTopics=()=>{ //我的话题
    Toast.show({
      icon:'loading',
      duration:0,
      maskClickable:false
    })
    eventService.getTopics(pages).then(res=>{
      if(res && res.status){
        Toast.clear()
        setTopicItem(res.data.data)
      }
    })
  }

  const getViewPoints=()=>{ // 我的观点
    Toast.show({
      icon:'loading',
      duration:0,
      maskClickable:false
    })
    eventService.getViewPoints(pages).then(res=>{
      if(res && res.status){
        Toast.clear()
        setViewpointItem(res.data.data)
      }
    })
  }

  const getComments=()=>{ // 我的评论
    Toast.show({
      icon:'loading',
      duration:0,
      maskClickable:false
    })
    eventService.getComments(pages).then(res=>{
      if(res && res.status){
        Toast.clear()
        setCommentItem(res.data.data)
      }
    })
  }

  const getFavoriteList=()=>{ //我的收藏
    Toast.show({
      icon:'loading',
      duration:0,
      maskClickable:false
    })
    eventService.getFavoriteList(pages).then(res=>{
      if(res && res.status){
        Toast.clear()
        setCollectItem(res.data.data)
      }
    })
  }

  return (
    <div className="commuityData">
      {!detail ? (
        null
      ) : (
        <>
          <BaseNavBar title={intl.get("my.myhome")} />
          <div className="commuityData_header">
            <img src={detail.avatar} alt="" />
            <span>{detail.nick_name}</span>
          </div>
          <div className="tabs">
            <Tabs
              activeKey={tabIndex + ''}
              onChange={(val) => {
                setTabIndex(Number(val))
              }}
            >
              <Tabs.Tab title={intl.get("my.topic")} key="0" />
              <Tabs.Tab title={intl.get("viewpoint")} key="1" />
              <Tabs.Tab title={intl.get("my.point_detail_text")} key="2" />
              <Tabs.Tab title={intl.get("collect")} key="3" />
            </Tabs>
            {
              tabIndex === 0 && <div className="list">
                {
                  topicItem.length !== 0 ? topicItem.map(item=>{
                    return <TopicItem key={item.id} data={item} />
                  })
                  :
                  <Empty description={intl.get('noData')} />
                }
              </div>
            }
            {
              tabIndex === 1 && <div className="list">
                {
                  viewpointItem.length !== 0 ? viewpointItem.map(item=>{
                    return <ViewpointItem key={item.id} data={item} />
                  })
                  :
                  <Empty description={intl.get('noData')} />
                }
              </div>
            }

            {
              tabIndex === 2 && <div className="list">
                {
                  commentItem.length !== 0 ? commentItem.map(item=>{
                    return <CommentItem key={item.id} data={item} />
                  })
                  :
                  <Empty description={intl.get('noData')} />
                }
              </div>
            }

            {
              tabIndex === 3 && <div className="list">
                {
                  collectItem.length !== 0 ? collectItem.map(item=>{
                    return <CollectItem resetData={getFavoriteList} key={item.id} data={item} />
                  })
                  :
                  <Empty description={intl.get('noData')} />
                }
              </div>
            }
          </div>
        </>
      )}
    </div>
  )

  return <div className="commuityData"></div>
}

const mapState = (state) => {
  return {
    count: state.count,
  }
}

export default connect(mapState)(CommuityData)
