import React, { useState, useEffect, createRef } from "react"
import intl from "react-intl-universal"
import { connect } from "react-redux"
import { Ellipsis,Toast } from "antd-mobile"
import pk_03 from '@/static/image/pk_03.png'
import { eventService } from "@/service"
import hot from '@/static/image/hot.png'
import _ from "underscore"
import { loginUrlRedirect } from '@/utils/auth'
import "./index.less"

const CommentItem = (props) => {
  const [data,setData] = useState(props.data)

  useEffect(()=>{
    setData(props.data)
    console.log(props.vote_list);
  },[props.data])

  //点赞 踩、取消点赞 踩
  const view_points_listtar =async (operation,type)=>{
    if(!props.islogin){
      loginUrlRedirect()
      return 
    }
    Toast.show({
      icon:'loading',
      maskClickable:false,
      duration:0
    })
    let {status,message} = await eventService.viewPointStar({
      operation,
      type,
      viewpoint_id:data.id
    })
    if(status){
      props.resetData()
    }else{
      Toast.clear()
      Toast.show({
        icon:'fail',
        content:message
      })
    }
  }

  const getvote = (id)=>{
    let val = '';
    props.vote_list && props.vote_list.map(item=>{
      if(item.id === id){
        val = item.support_name
      }
    })
    return val;
  }

  return (
    <div className="commentItem">
      <img className="head_img" src={data.belongs_user.avatar} alt="" />
      <div className="right">
          <p>
            <span>
              {data.belongs_user.nick_name}
              {!_.isEmpty(data.belongs_user.type) && data.belongs_user.type.type ===0 && <span className="author_tag success">{data.belongs_user.type.type_name}</span>}
            {!_.isEmpty(data.belongs_user.type) && data.belongs_user.type.type ===1 && <span className="author_tag warn">{data.belongs_user.type.type_name}</span>}
            {!_.isEmpty(data.belongs_user.type) && data.belongs_user.type.type ===4 && <span className="author_tag error">{data.belongs_user.type.type_name}</span>}
            {!_.isEmpty(data.belongs_user.type) && data.belongs_user.type.type ===5 && <span className="author_tag processing">{data.belongs_user.type.type_name}</span>}
            {!_.isEmpty(data.belongs_user.tntm_user_info) && data.belongs_user.tntm_user_info.online_status === false && <span className="author_tag purple">{intl.get('userType')}</span>}
            {!_.isEmpty(data.belongs_user.tntm_user_info) && data.belongs_user.tntm_user_info.online_status === true && localStorage.getItem('market_id') === '36' && <span className="author_tag remote">{intl.get('remote')}</span>}
            </span>
           {props.showHot && <img src={hot} alt="" /> }
           </p>
          {
            props.topicType !==1 && <div className="support"><Ellipsis rows={1} direction='middle' content={`${intl.get('support')} ${'：'}  ${getvote(data.topic_vote_id)}`} />  </div>
          }
          <div className='comment_content' onClick={(e)=>{
              e.stopPropagation();
              if(!props.islogin){
                loginUrlRedirect()
                return 
              }
              props.history.push(`/pointDetail/${data.id}`)
            }}>
            <Ellipsis
              rows={3}
              content={data.content}
            />
          </div>
          <div className="footer">
            <span className={data.is_star === 1 ? "active" : "" }>
                <i className="iconfont" onClick={()=>view_points_listtar('star',1)}>&#xe602;</i>
                <i className="iconfont" onClick={()=>view_points_listtar('unstar',1)}>&#xec8c;</i> 
                {data.support_count}
            </span>
            <span className={data.is_star === 2 ? "active" : "" }>
                <i className="iconfont" onClick={()=>view_points_listtar('star',2)}>&#xe600;</i>
                <i className="iconfont" onClick={()=>view_points_listtar('unstar',2)}>&#xe68f;</i>
                {data.against_count}
            </span>
            <span><i className="iconfont y" onClick={()=>{
              if(!props.islogin){
                loginUrlRedirect()
                return 
              }
              props.history.push(`/pointDetail/${data.id}`)
            }}>&#xe744;</i>{data.comment_count}</span>
            <p>{data.time_tran}</p>
          </div>
      </div>
    </div>
  )
}



const mapState = (state) => {
  return {
    islogin:state.app.islogin
  }
}

export default connect(mapState)(CommentItem)
