import React from 'react'
import ReactDOM from "react-dom"
import App from "./App"
import 'antd-mobile/es/global'
import "swiper"
import "swiper/css/bundle"
import './styles/global.less'
import "./utils/flexible.js"
// import VConsole from 'vconsole';
// const vConsole = new VConsole();

ReactDOM.render(
  <App/>,
  document.getElementById('root')
)
