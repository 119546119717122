import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import intl from "react-intl-universal"
import _ from "underscore"
import { Dropdown, TextArea, Button, Form, Input, Popup, DatePickerView,Toast,Dialog, Modal, Switch } from "antd-mobile"
// import { DatePicker } from 'antd';
import ppo from 'ppo'
import Loading from "@/components/Layouts/Loading"
import Moment from 'moment'
import { eventService } from "@/service"
import { BaseNavBar } from "@/components/Layouts"
import CreateSelect from '@/components/CreateSelect'
import "./index.less"

const timeVal = new Date().getFullYear() + '/'+ (new Date().getMonth()+1) + '/' + (new Date().getDate()+1) + ' ' + new Date().getHours() + ':00:00'
const minTime = Moment().add(0,'day').format('YYYY/MM/DD') //暂时改为0
let timeVal2 = '';
const CreateTopic = (props) => {
  	const [detail, setDetail] = useState(null)
  	const [topicCatgeList,setTopicCatgeList] = useState([]);
	const [topicCatgeValue,setTopicCatgeValue] = useState();//选择话题分类
  	const [topicTypeList,setTopicTypeList] = useState([]); 
	const [topicTypeValue,setTopicTypeValue] = useState(true);//选择话题类型
	const [topic_visible, setTopic_visible] = useState(false)
	const [time, setTime] = useState(new Date(timeVal))
	const [topic_name,setTopic_name] = useState('')
	const [userData,setUserData] = useState({});
	const [desc,setDesc] = useState('');
	const [form] = Form.useForm();
	let {currentLocale} = intl.options;

  useEffect(() => {
	  console.log(currentLocale);
    window.scrollTo(0,0)
	getData()
  }, [])

  const getData = ()=>{
	  Promise.all([
		eventService.mySpaceUser(), //个人信息
		eventService.getTopicCateList(), //获取分类

	  ]).then(res=>{
		if(res[0] && res[0].status){
			setUserData(res[0].data)
		}
		if(res[1] && res[1].status){
			setTopicCatgeList(res[1].data)
		}
		   
		  //获取修改数据
		  if(ppo.getUrlParam('id')){
			eventService.getTopicDetail(ppo.getUrlParam('id')).then(msg=>{
				setDetail(res);
				if(msg && msg.status){
					let data = msg.data;
					let type_id = data.type_id;
					if(type_id !== 1){
						form.setFieldsValue({
							belongs_vote:data.vote_list
						})
						setTopicTypeValue(true);
					}else{
						setTopicTypeValue(false);
					}
					data.belongs_type.cate_name = data.belongs_type.type_name;
					setTopicCatgeValue(data.belongs_cate);
					setTopic_name(data.topic_name)
					setDesc(data.desc)
				}
			})
		}else{
			setDetail(res);
		}

	  })
  }

	const labelRenderer = (type, data) => {
		switch (type) {
			case 'year':
				return data + intl.get('year')
			case 'month':
				return data + intl.get('month')
			case 'day':
				return data + intl.get('day')
			case 'hour':
				return data + intl.get('hour')
			default:
				return data
		}
	}

	const onFinish = values =>{
		save(values)
	}

	const save = (values)=>{
		if(!topicCatgeValue || !topic_name){
			Toast.show({
				icon:'fail',
				content:intl.get('error_topic')
			})
			return 
		}
		let params = {
			topic_name,
			desc,
			end_time:time ? Moment(time).format('YYYY-MM-DD HH:mm') : timeVal,
			vote:values ? values.belongs_vote : [],
			cate_id:topicCatgeValue.id,
			type_id: (values && values.belongs_vote)  ? values.belongs_vote.length === 2 ? 2 : 3 : 1
		};
		Toast.show({
			icon:"loading",
			duration:0
		})
		console.log(params);
		eventService.createTopic(params).then(res=>{
			Toast.clear()
			if(res.status){
				Modal.show({
					title: intl.get("createSuccess"),
					content: <>
						<p style={{marginBottom:'6px'}}>{intl.get('createSuccess2')}</p>
						<p style={{margin:'0'}}>{intl.get('createSuccess_desc')}</p>
					</>,
					showCloseButton: false,
					closeOnAction:true,
					confirmText:intl.get("createOk"),
					actions:[
						{
							key:'ok',
							text:intl.get("createOk")
						}
					],
					onAction:(val)=>{
						props.history.go(-1)
					}
				})
			}else{
				Toast.show({
					icon:'fail',
					content:res.message
				})
			}
		}).finally(()=>{
			// Toast.clear()
		})
	}

  return (
    <div className="createTopic">
      {!detail ? (
        <Loading />
      ) : (
        <>
          <BaseNavBar title={intl.get("topic_detail")} />
          <div className="header">
            <div className="author">
              <p className="img">
                <img className="headimg" src={userData.avatar} alt="" />
              </p>
              <div className="right">
                <div className="name">{userData.nick_name}</div>
              </div>
            </div>
          </div>

			<div className="select">
				<CreateSelect
					placeholder={intl.get('selectType')}
					list={topicCatgeList}
					data={topicCatgeValue}
					setData={(data)=>setTopicCatgeValue(data)	}
				/>
			</div>
			{/* <div className="select">
				<CreateSelect
					placeholder={intl.get('selectClass')}
					list={topicTypeList}
					data={topicTypeValue}
					setData={(data)=>setTopicTypeValue(data)	}
				/>
			</div> */}
			<div className="textarea">
				<TextArea
					placeholder={intl.get('topic_text')}
					autoSize={{ minRows: 3, maxRows: 5 }}
					showCount
					maxLength={ currentLocale === 'en' ? 300 :150}
					value={topic_name}
					onChange={val=>{
						setTopic_name(val)
					}}
				/>
			</div>
			<div className="textarea">
				<TextArea
					placeholder={intl.get('topic_text_replenish')}
					autoSize={{ minRows: 3, maxRows: 5 }}
					showCount
					value={desc}
					onChange={val=>{
						setDesc(val)
					}}
				/>
			</div>
			<div className="openVote">
				<span>{intl.get('openVote')}</span><Switch checked={topicTypeValue} onChange={checked => {
					setTopicTypeValue(checked)
				}}/>
			</div>
			{
				topicTypeValue && topicTypeValue.id !== 1 && 
					<div className="setVoteList">
						<p>{intl.get('set_topic_list')}</p>
						<Form
							onFinish={onFinish}
							form={form}
							initialValues={{
								belongs_vote: [{},{}],
							}}
							layout='horizontal'
						>
							<Form.Array
								name='belongs_vote'
								onAdd={operation => operation.add({support_name:''})}
								renderAdd={() => (
									<span>
										<i className="iconfont" style={{color:'#DDDDDD',fontSize:'26px'}}>&#xe641;</i>
									</span>
								)}
							>
								{(fields,{ remove }) =>
									fields.map(({ index }) => (
										<div className="formItemBox">
											<Form.Item 
												name={[index, 'support_name']} 
												label={intl.get('option',{i:index+1})}
												rules={[{ required: true}]}
											>
												<Input placeholder={intl.get('topic_list_text')} />
											</Form.Item>
											{
												index !== 0 && index !== 1 && <div className="remove_add">
													<i onClick={() => remove(index)} className="iconfont">&#xe8b6;</i>
												</div>
											}
											
										</div>
									))
								}
							</Form.Array>
						</Form>
					</div>
					}
					
					{
						topicTypeValue && topicTypeValue.id !== 1 && 
							<div className="setVoteList">
								<p>{intl.get('vote_time')}</p>
								<div className="time">
									<div className="label">{intl.get('end_time')}</div>
									<p onClick={()=>{setTopic_visible(true)}}> <i className="iconfont">&#xe64d;</i>{ time ? Moment(time).format('YYYY-MM-DD HH:mm') : timeVal}</p>
								</div>
							</div>
					}
					
					<div className="createTopicFooter">
						<Button onClick={()=>{
							props.history.go(-1)
						}}><i className="iconfont">&#xe874;</i>{intl.get('cancel_public_topic')}</Button>
						<Button onClick={() =>{
							if(topicTypeValue && topicTypeValue.id !==1){
								form.submit()
							}else{
								save()
							}
							
						}}><i className="iconfont">&#xe6bd;</i>{intl.get('public_topic')}</Button>
					</div>
        </>
      )}
			<Popup
				visible={topic_visible}
				onMaskClick={() => {
					setTopic_visible(false)
				}}
				bodyStyle={{
					borderTopLeftRadius: "8px",
					borderTopRightRadius: "8px",
				}}
			>
				<div className="popList">
					<div className="title">
						{intl.get('select_time')} <span onClick={()=>{setTopic_visible(false);setTime(timeVal2)}}>{intl.get('ok')}</span>
					</div>
					<div className="popBody">
					<DatePickerView
						min={ new Date(minTime)}
						defaultValue={new Date(timeVal)}
						onChange={val=>{timeVal2 = val}}
						precision='hour'
						renderLabel={labelRenderer}
					/>
					</div>
				</div>
			</Popup>
    </div>
  )
}

const mapState = (state) => {
  return {
    select: state.app.select,
  }
}

export default connect(mapState)(CreateTopic)
