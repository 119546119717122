import React, { useState, useEffect } from 'react';
import { connect } from "react-redux"
import { Button, Empty } from 'antd-mobile';
import intl from "react-intl-universal"
import { BaseNavBar } from "@/components/Layouts"
import { eventService } from "@/service"
import setting02_03 from '@/static/image/setting02_03.png'
import setting_b from '@/static/image/setting_b.png'
import setting_left from '@/static/image/setting_left.png'
import Loading from "@/components/Layouts/Loading"
import './index.less';

const SettingInfo = (props)=>{
  const [data,setData] = useState([]);
  const [loading,setloading] = useState(false);
  useEffect(() => {
    getData()
  }, []);

  const getData = async()=>{
    setloading(true);
    let params = {
      page:1,
      per_page:1000
    }
    let {data,status} =  await eventService.getNotify(params);
    if(status){
      setData(data.data)
    }
    setloading(false);
  }
  const toDetail =(data)=>{
    if(!data.read_at){
        updateRead([data.id]);
    }
    if(data.type === 8){
      props.history.push(`/createTopic?id=${data.topic_id}`);
      return 
    }
    if(data.viewpoint_id !==0){
      props.history.push(`/pointDetail/${data.viewpoint_id}`);
      return
    }
    if(data.topic_id !==0){
      props.history.push(`/topic/detail/${data.topic_id}`);
      return
    }
  }

  const updateRead =  (idList)=>{
    return eventService.updateReadStatus(idList);
  }
  const clear = ()=>{
    setloading(true);
    let idList = data.map(item=>{return item.id})
    updateRead(idList).then(()=>{
      let list = data.map(item=>{
        item.read_at = true;
        return item
      })
      setData(list)
      setloading(false);
    })
  }

  return <div className='settingInfo'>
     <BaseNavBar title={<>{intl.get("my.setting_info")} <i onClick={clear} className='iconfont clear'>&#xe65d;</i> </>}/>
     <div className="settingInfo_list">
       {
         loading ? <Loading />
         :
          data && data.length !==0 ? data.map(item=>{
            return <div className="settingInfo_list_item" key={item.id}>
              <p>{item.created_at}</p>
              <div className="content">
                <div className="left"><img src={ item.read_at ? setting_b : setting02_03} alt="" /></div>
                <div className="right">
                  <img src={setting_left} alt="" />
                  <p>{item.message} </p>
                  <span onClick={()=>toDetail(item)}>{intl.get('my.detail') + ' >'}</span>
                </div>
              </div>
              </div>
          })
          :
          <Empty style={{marginTop:'20vh'}} description={intl.get('noData')}/>
        }
     </div>
  </div>
}

const mapState = (state) => {
  return {
    count: state.count
  }
}

export default connect(mapState)(SettingInfo);