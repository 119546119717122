class EventService {
  constructor (clientAxios) {
    this.http = clientAxios
  }

    getToken(params){
      let url = '/api/v1/getTokenByToken'
      return this.http.getJson(url, params)
    }
    getBanner(){
      let url = '/api/v1/topic/getBannerList';
      return this.http.getJson(url)
    }

    /**
   * 登录
   * @param params
   * @returns {Promise}
   */
    login (params) {
      let url = '/api/v1/login'
      return this.http.getJson(url, params)
    };
      /**
   * 退出登录
   * @param params
   * @returns {Promise}
   */
  loginOut (params) {
    let url = '/api/v1/logout'
    return this.http.getJson(url, params)
  };

  /**
   * 首页推荐数据
   * @param params
   * @returns {Promise}
   */
  getTopic (params) {
    let url = '/api/v1/topic/topicList'
    return this.http.getJson(url, params)
  };

  /**
   * 话题分类
   * @param params
   * @returns {Promise}
   */
   getTopicCateList (params) {
    let url = '/api/v1/getTopicCateList'
    return this.http.getJson(url, params)
  };
  /**
   * 话题类型
   * @param params
   * @returns {Promise}
   */
   getTopicTypeList () {
    let url = `/api/v1/getTopicTypeList`
    return this.http.getJson(url)
  };

  /**
   * 话题页详情
   * @param params
   * @returns {Promise}
   */
   getTopicDetail (topic_id,params) {
    let url = `/api/v1/topic/topic/${topic_id}`
    return this.http.getJson(url,params)
  };

  /**
   * 观点列表
   * @param params
   * @returns {Promise}
   */
   getViewpoint (params) {
    let url = `/api/v1/viewpoint/viewpoint`
    return this.http.getJson(url,params)
  };
  
  /**
  * 收藏列表
  * @param params
  * @returns {Promise}
  */
   favoriteUserList (params) {
   let url = `/api/v1/topic/favoriteUserList`
   return this.http.postJson(url,params)
 };

  /**
  * 投票
  * @param params
  * @returns {Promise}
  */
   topicVote (params) {
    let url = `/api/v1/topic/vote`
    return this.http.postJson(url,params)
  };

  /**
  * 投票用户
  * @param params
  * @returns {Promise}
  */
   getVoteUserListByTopicId (params) {
    let url = `/api/v1/topic/getVoteUserListByTopicId`
    return this.http.postJson(url,params)
  };

  /**
  * 收藏/取消收藏
  * @param params
  * @returns {Promise}
  */
   favoriteTopic (params) {
    let url = `/api/v1/topic/favoriteTopic`
    return this.http.postJson(url,params)
  };

  /**
  * 点赞
  * @param params
  * @returns {Promise}
  */
   viewPointStar (params) {
    let url = `/api/v1/viewpoint/viewPointStar`
    return this.http.getJson(url,params)
  };

  /**
  * 发布话题
  * @param params
  * @returns {Promise}
  */
  createTopic (params) {
    let url = `/api/v1/topic/topic`
    return this.http.postJson(url,params)
  };

  /**
  * 发布观点
  * @param params
  * @returns {Promise}
  */
   viewpoint (params) {
    let url = `/api/v1/viewpoint/viewpoint`
    return this.http.postJson(url,params)
  };
  /**
  * 观点详情
  * @param params
  * @returns {Promise}
  */
   viewpointDetail (id) {
    let url = `/api/v1/viewpoint/viewpoint/${id}`
    return this.http.getJson(url)
  };

  /**
  * 观点下的评论
  * @param params
  * @returns {Promise}
  */
   getCommentList (params) {
    let url = `/api/v1/comment/commentList`
    return this.http.postJson(url,params)
  };

  /**
  * 发表评论
  * @param params
  * @returns {Promise}
  */
   saveComment (params) {
    let url = `/api/v1/comment/comment`
    return this.http.postJson(url,params)
  };

  /**
  * 点赞评论
  * @param params
  * @returns {Promise}
  */
  savecommentStar (params) {
    let url = `/api/v1/comment/commentStar`
    return this.http.getJson(url,params)
  };
   /**
  * 删除评论
  * @param params
  * @returns {Promise}
  */
  savecommentDel (params) {
    let url = `/api/v1/comment/commentDel`
    return this.http.getJson(url,params)
  };

   /**
  * 用户信息
  * @param params
  * @returns {Promise}
  */
  mySpaceUser (params) {
    let url = `/api/v1/mySpace/user`
    return this.http.getJson(url,params)
  };

   /**
  * 用户话题
  * @param params
  * @returns {Promise}
  */
  getTopics (params) {
    let url = `/api/v1/mySpace/getTopics`
    return this.http.getJson(url,params)
  };

   /**
  * 用户话题
  * @param params
  * @returns {Promise}
  */
  getViewPoints (params) {
    let url = `/api/v1/mySpace/getViewPoints`
    return this.http.getJson(url,params)
  };

   /**
  * 用户评论
  * @param params
  * @returns {Promise}
  */
    getComments (params) {
      let url = `/api/v1/mySpace/getComments`
      return this.http.getJson(url,params)
    };

  /**
  * 用户收藏
  * @param params
  * @returns {Promise}
  */
   getFavoriteList (params) {
    let url = `/api/v1/mySpace/getFavoriteList`
    return this.http.getJson(url,params)
  };

  /**
  * 获取系统消息
  * @param params
  * @returns {Promise}
  */
  getNotify (params) {
    let url = `/api/v1/mySpace/getNotify`
    return this.http.getJson(url,params)
  };

  /**
  * 更新系统消息已读
  * @param params
  * @returns {Promise}
  */
  updateReadStatus (params) {
    let url = `/api/v1/mySpace/updateReadStatus`
    return this.http.postJson(url,params)
  };

  /**
  * 查询系统消息未读条数
  * @param params
  * @returns {Promise}
  */
   getNotifyCount (params) {
    let url = `/api/v1/mySpace/getNotifyCount`
    return this.http.getJson(url,params)
  };



  


 


}
export default {
  EventService,
}
