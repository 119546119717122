import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import intl, { get } from "react-intl-universal"
import _ from "underscore"
import { PullToRefresh, Button, InfiniteScroll, Tabs, Popup, Toast,Empty,TextArea } from "antd-mobile"
import appActions from "@/store/modules/app"
import Loading from "@/components/Layouts/Loading"
import CommentItem from "@/components/CommentItem"
import CollectItem from "@/components/CollectItem"
import VoteItem from "@/components/VoteItem"
import { eventService } from "@/service"
import { BaseNavBar } from "@/components/Layouts"
import PointViewItem from '@/components/PointViewItem'
import VIP from "@/static/image/vip.png"
import sandian_icon from "@/static/image/sandainicon.png"
import pk_03 from "@/static/image/pk_03.png"
import "./index.less"

const PointDetail = (props) => {
  const [detail, setDetail] = useState(null);
  const [point_list,setPoint_list] = useState([])
  const [topic_success,setTopic_success] = useState(false)
  const [textareaValue,setTextareaValue] = useState('')

  useEffect(() => {
    window.scrollTo(0,0)
    getData();
  }, [])
  const viewpointDetail = async ()=>{ // 获取投票数据
     return eventService.viewpointDetail(props.match.params.id)
  }
  const getCommentList = async ()=>{
      let params={
        viewpoint_id:props.match.params.id,
        reply_show_count:10000,
        page:1,
        per_page:10000,
        sortField:'star_info_count'
      }
      return eventService.getCommentList(params);
  }

  const getData = (isloading)=>{
    if(isloading){
        Toast.show({
            icon:"loading",
            duration:0,
            maskClickable:false
        })
    }
      Promise.all([
        viewpointDetail(),
        getCommentList()
      ]).then(res=>{
        Toast.clear()
        if(res[0] && res[0].status){
            setDetail(res[0].data)
        }
        if(res[1] && res[1].status){
            setPoint_list(res[1].data.data)
        }
      })
  }

  //点赞
  const view_points_listtar = async (operation,type)=>{
    Toast.show({
      icon:'loading',
      maskClickable:false,
      duration:0
    })
    let {status,message} = await eventService.viewPointStar({
      operation,
      type,
      viewpoint_id:detail.id
    })
    if(status){
        getData(true)
    }else{
      Toast.clear()
      Toast.show({
        icon:'fail',
        content:message
      })
    }
  }
  
  //发布评论
  const saveComment = async ()=>{
    if(!textareaValue){
        Toast.show({
          icon:"fail",
          content:intl.get('point_detail_error')
        })
        return false
      }
      Toast.show({
        icon:'loading',
        maskClickable:false,
        duration:0
      })
      let params = {
        content:textareaValue,
        topic_id:detail.topic_id,
        viewpoint_id:detail.id,
        parent_id:0,
        reply_id:0,
        receive_uid:detail.belongs_user.id
      }
      eventService.saveComment(params).then(res=>{
          if(res && res.status){
            setTextareaValue('');
            getData(true);
            setTopic_success(false)
          }else{
            Toast.show({
                icon:"fail",
                content:res.message
              })
          }
      })
  }

  const getVoteName = (list,val)=>{
    let txt='';
    if(list){
      list.map(item=>{
        if(item.id === val){
          txt = item.support_name
        }
      })
    }
    return txt
  }

  return (
    <div className="pointDetail">
      {!detail ? (
        <Loading />
      ) : (
        <>
          <BaseNavBar title={intl.get("point_detail")} />
          <div className="header">
            <div className="author">
              <p className="img">
                <img
                  className="headimg"
                  src={detail.belongs_user.avatar}
                  alt=""
                />
                {detail.belongs_user.is_official && (
                  <img className="vip" src={VIP} alt="" />
                )}
              </p>
              <div className="right">
                <div className="name">
                  {detail.belongs_user.nick_name}
                  {!_.isEmpty(detail.belongs_user.type) && detail.belongs_user.type.type ===0 && <span className="author_tag success">{detail.belongs_user.type.type_name}</span>}
                  {!_.isEmpty(detail.belongs_user.type) && detail.belongs_user.type.type ===1 && <span className="author_tag warn">{detail.belongs_user.type.type_name}</span>}
                  {!_.isEmpty(detail.belongs_user.type) && detail.belongs_user.type.type ===4 && <span className="author_tag error">{detail.belongs_user.type.type_name}</span>}
                  {!_.isEmpty(detail.belongs_user.type) && detail.belongs_user.type.type ===5 && <span className="author_tag processing">{detail.belongs_user.type.type_name}</span>}
                  {!_.isEmpty(detail.belongs_user.tntm_user_info) && detail.belongs_user.tntm_user_info.online_status === false && <span className="author_tag purple">{intl.get('userType')}</span>}
                  {!_.isEmpty(detail.belongs_user.tntm_user_info) && detail.belongs_user.tntm_user_info.online_status === true && localStorage.getItem('market_id') === '36' && <span className="author_tag remote">{intl.get('remote')}</span>}
                  {detail.belongs_user.is_official && (
                    <p>{intl.get("official")}</p>
                  )}
                </div>
                <div className="time">{detail.time_tran}</div>
              </div>
            </div>
            <div className="title">{detail.belongs_topic.topic_name}</div>
            {detail.belongs_topic.type_id !== 1 && (
              <div className="voteInfo">
                {intl.get("support")}：
                {
                  !_.isEmpty(detail.belongs_topic.vote_list) && <span>{getVoteName(detail.belongs_topic.vote_list,detail.topic_vote_id)}</span>
                }
              </div>
            )}

            <div className="desc">{detail.content}</div>
            <div className="header_book">
              {intl.get("onlooker")} {detail.browse_info_count}
            </div>
          </div>
          <div className="tab_list">
            <li className={detail.is_star === 1 ? "active" : ""}>
              <i
                className="iconfont"
                onClick={() => view_points_listtar("star", 1)}
              >
                &#xe602;
              </i>
              <i
                className="iconfont"
                onClick={() => view_points_listtar("unstar", 1)}
              >
                &#xec8c;
              </i>
              <p>{detail.supportCount}</p>
            </li>
            <li className={detail.is_star === 2 ? "active" : ""}>
              <i
                className="iconfont"
                onClick={() => view_points_listtar("star", 2)}
              >
                &#xe600;
              </i>
              <i
                className="iconfont"
                onClick={() => view_points_listtar("unstar", 2)}
              >
                &#xe68f;
              </i>
              <p>{detail.againstCount}</p>
            </li>
            <li>
              <i className="iconfont y">&#xe744;</i>
              <p>{detail.commentCount}</p>
            </li>
            {/* <li>
              <i className="iconfont y">&#xe8b0;</i>
              <p>{intl.get("share")}</p>
            </li> */}
          </div>
          {!topic_success && (
            <div className="comment">
              <div className="top">
                {intl.get("point_detail_text")}
                <p onClick={() => setTopic_success(true)}>
                  {intl.get("write_point_text")}
                </p>
              </div>
              {point_list.length !== 0 ? (
                point_list.map((item) => {
                  return (
                    <div className="item_comment" key={item.id}>
                      <PointViewItem reset={()=>{
                        getData(true)
                      }} viewpoint_id={detail.id} data={item}></PointViewItem>
                    </div>
                  )
                })
              ) : (
                <Empty description={intl.get("noData")} />
              )}
            </div>
          )}

          {topic_success && (
            <div className="topic_success">
              <div className="textarea">
                <TextArea
                  value={textareaValue}
                  onChange={(val) => {
                    setTextareaValue(val)
                  }}
                  placeholder={intl.get("point_detail_error")}
                  autoSize={{ minRows: 7, maxRows: 7 }}
                />
              </div>
              <div className="topic_success_footer">
                <Button className="nobg button" onClick={() => {setTopic_success(false);setTextareaValue('')}}>
                    <i className="iconfont">&#xe874;</i>
                    {intl.get("cancel")}
                </Button>
                <Button className="button" onClick={saveComment}><i className="iconfont">&#xe617;</i>{intl.get("ok")}</Button>
              </div>
             
            </div>
          )}
        </>
      )}
    </div>
  )
}

const mapState = (state) => {
  return {
    select: state.app.select,
  }
}

export default connect(mapState)(PointDetail)
