import React, { useEffect, useState } from 'react'
import { SpinLoading } from 'antd-mobile'



const Loading = ()=>{
  return (
    <div className='main_loading'><SpinLoading color='#10C5B6' /></div>
  )
}

export default Loading;