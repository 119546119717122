import React from 'react';
import { withRouter } from 'react-router-dom'
import { NavBar } from 'antd-mobile';
import { useHistory } from "react-router-dom";
import './NavBar.less'

const BaseNavBar=(props)=>{
  let history = useHistory()

  const goBack = () => {
    props.history.length > 1 ? props.history.go(-1) : history.push(`/home`);
  }

  return (
      <div className='navbar'>
        <NavBar
            mode="light"
            onBack={() => goBack()}
            >{props.title}</NavBar>
      </div>
    
  )
}

export default withRouter(BaseNavBar);