import Home from "@/pages/home/index"
import Me from "@/pages/me/index"
import Topic from "@/pages/topic/index"
import Connection from "@/pages/connection/index"
import Index from "@/pages/index/index"
import TopicDetail from '@/pages/topicDetail/index'
import CreateTopic from '@/pages/createTopic/index'
import PointDetail from '@/pages/pointDetail/index'
import SettingInfo from '@/pages/settingInfo/index'
import CommuityData from '@/pages/commuityData/index'

const routes = [
  {
    path: "/",
    component: Index,
    routes:[
      {
        path: "/home",
        exact: true,
        component: Home
      },
      {
        path: "/topic",
        exact: true,
        name:"话题",
        component: Topic
      },
      {
        path: "/connection",
        exact: true,
        name:"人脉",
        component: Connection
      },
      {
        path: "/me",
        name:"我的",
        exact: true,
        component: Me
      },
      {
        path:'/topic/detail/:id',
        name:"话题详情",
        exact: true,
        component:TopicDetail
      },
      {
        path:'/pointDetail/:id',
        name:"观点详情",
        exact: true,
        component:PointDetail
      },
      {
        path:'/createTopic',
        exact: true,
        name:"新建话题",
        component:CreateTopic
      },
      {
        path:'/settingInfo',
        exact: true,
        name:"系统消息",
        component:SettingInfo
      },
      {
        path:'/commuityData',
        exact: true,
        name:"社区数据",
        component:CommuityData
      }
    ]
  },
  
  
      
  
  // {
  //   path: "/me/:id",
  //   exact: true,
  //   component: About
  // },
  // {
  //   path: "/about",
  //   component: About
  // },
  
];

export default routes;