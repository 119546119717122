import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import _ from 'underscore'
import { Ellipsis } from 'antd-mobile'
import pk_title_icon from '@/static/image/pk_title_icon.png'
import sandian_icon from '@/static/image/sandainicon.png'
import pk_03 from '@/static/image/pk_03.png'
import pk_info_left from '@/static/image/pk_info_left.png'
import pk_info_right from '@/static/image/pk_info_right.png'
import BasicsIcon from '../BasicsIcon'
import './index.less'


const BattleTopic = (props)=>{


  const renderLeft = (data)=>{
    let params = null;
    data.view_points_list.map(item=>{
      let id = data.vote_list[0].id;
      if(item.belongs_vote.id === id){
        params = item;
      }
    })

    if(params){
      return <div className='info'>
        <img src={pk_info_left} alt="" />
        <Ellipsis
          rows={3}
          content={params.content}
        />
        {
          !_.isEmpty(params.belongs_user) && <div className='author'>
          <img src={params.belongs_user.avatar} alt="" /> <span className='name'>{params.belongs_user.nick_name}</span>
          {!_.isEmpty(params.belongs_user.type) && params.belongs_user.type.type ===0 && <span className="author_tag success">{params.belongs_user.type.type_name}</span>}
          {!_.isEmpty(params.belongs_user.type) && params.belongs_user.type.type ===1 && <span className="author_tag warn">{params.belongs_user.type.type_name}</span>}
          {!_.isEmpty(params.belongs_user.type) && params.belongs_user.type.type ===4 && <span className="author_tag error">{params.belongs_user.type.type_name}</span>}
          {!_.isEmpty(params.belongs_user.type) && params.belongs_user.type.type ===5 && <span className="author_tag processing">{params.belongs_user.type.type_name}</span>}
          {!_.isEmpty(params.belongs_user.tntm_user_info) && params.belongs_user.tntm_user_info.online_status === false && <span className="author_tag purple">{intl.get('userType')}</span>}
          {!_.isEmpty(params.belongs_user.tntm_user_info) && params.belongs_user.tntm_user_info.online_status === true && localStorage.getItem('market_id') === '36' && <span className="author_tag remote">{intl.get('remote')}</span>}
          {
            _.isEmpty(params.belongs_user.type) || (!_.isEmpty(params.belongs_user.type) && params.belongs_user.type.type === 100) && <span className='time'>{params.time_tran}</span>
          }
          
        </div>
        }
        
      </div>
    }
  }

  const renderRight=()=>{
    let params = null;
    data.view_points_list.map(item=>{
      let id = data.vote_list[1].id;
      if(item.belongs_vote.id === id){
        params = item;
      }
    })

    if(params){
      return <div className='info'>
      <img src={pk_info_right} alt="" />
      <Ellipsis
        rows={3}
        content={params.content}
      />
      {
         !_.isEmpty(params.belongs_user) && <div className='author'>
         <img src={params.belongs_user.avatar} alt="" /> <span className='name'>{params.belongs_user.nick_name}</span>
         {!_.isEmpty(params.belongs_user.type) && params.belongs_user.type.type ===0 && <span className="author_tag success">{params.belongs_user.type.type_name}</span>}
          {!_.isEmpty(params.belongs_user.type) && params.belongs_user.type.type ===1 && <span className="author_tag warn">{params.belongs_user.type.type_name}</span>}
          {!_.isEmpty(params.belongs_user.type) && params.belongs_user.type.type ===4 && <span className="author_tag error">{params.belongs_user.type.type_name}</span>}
          {!_.isEmpty(params.belongs_user.type) && params.belongs_user.type.type ===5 && <span className="author_tag processing">{params.belongs_user.type.type_name}</span>}
          {!_.isEmpty(params.belongs_user.tntm_user_info) && params.belongs_user.tntm_user_info.online_status === false && <span className="author_tag purple">{intl.get('userType')}</span>}
          {!_.isEmpty(params.belongs_user.tntm_user_info) && params.belongs_user.tntm_user_info.online_status === true && localStorage.getItem('market_id') === '36' && <span className="author_tag remote">{intl.get('remote')}</span>}
          {
            _.isEmpty(params.belongs_user.type) || (!_.isEmpty(params.belongs_user.type) && params.belongs_user.type.type ===100) && <span className='time'>{params.time_tran}</span>
          }
       </div>
      }
      
    </div>
    }
  }

  const data = props.data || {}
  return (
    <div className='battleTopic'>
      {
        props.top?  <div className='title_icon_top'><BasicsIcon /></div>  : <div className='title_icon'><img src={pk_title_icon} alt="" />{intl.get('template.pk')}</div>
      }
       <div className="title">{data.topic_name}</div>
       {
         !_.isEmpty(data.vote_list) && (data.vote_list[0].ratio !==0 || data.vote_list[1].ratio !==0) && <div className='pk_ho'>
         <div className='num'>{data.vote_list[0].ratio + "%"}</div>
         <div className='line_box'>
           <div className={data.vote_list[0].ratio !==0 && data.vote_list[0].ratio !==100 ? 'line' : 'line borderReader'}
                style={{width:data.vote_list[0].ratio + '%'}}
            >
              {
             (data.vote_list[0].ratio !==0 && data.vote_list[1].ratio !==0) && <img src={sandian_icon} alt="" />
           }
            </div>
           <div className={data.vote_list[1].ratio !==0 && data.vote_list[1].ratio !==100 ? 'line' : 'line borderReader2'}
                style={{width:data.vote_list[1].ratio + '%'}}></div>
         </div>
         <div className='num'>{data.vote_list[1].ratio + "%"}</div>
       </div>
       }
        
        {
          !_.isEmpty(data.vote_list) && <div className='desc'>
            <div>
              <p className={ (!_.isEmpty(data.vote_info) && data.vote_info.topic_vote_id === data.vote_list[0].id) ? 'active' : ''}>{data.vote_list[0].support_name}</p>
            </div>
          <img src={pk_03} alt="" />
          <div>
            <p className={ (!_.isEmpty(data.vote_info) && data.vote_info.topic_vote_id === data.vote_list[1].id) ? 'active' : ''}>{data.vote_list[1].support_name}</p>
          </div>
        </div>
        }
        
        {
          props.type === 'recommend' && !_.isEmpty(data.view_points_list) && <div className='comment'>
            <div className='left'>
              {!_.isEmpty(data.vote_list) && !_.isEmpty(data.view_points_list) && renderLeft(data)}
            </div>
            <div className="right">
            {!_.isEmpty(data.vote_list) && !_.isEmpty(data.view_points_list) && renderRight(data)}
            </div>
        </div>
        }
        
        <div className='footer'>{data.person_total} {intl.get('take')} <span>{intl.get('end',{time:data.end_time})}</span> </div>
        {props.type === "topic" && (
        <>
          <div className="author_list">
            {
              !_.isEmpty(data.belongs_user) && <div className="author_list_item">
              {/* {intl.get("template.preside")} */}
              <img src={data.belongs_user.avatar} alt="" />
              <span>{data.belongs_user.nick_name}</span>
              {!_.isEmpty(data.belongs_user.type) && data.belongs_user.type.type ===0 && <span className="author_tag success">{data.belongs_user.type.type_name}</span>}
              {!_.isEmpty(data.belongs_user.type) && data.belongs_user.type.type ===1 && <span className="author_tag warn">{data.belongs_user.type.type_name}</span>}
              {!_.isEmpty(data.belongs_user.type) && data.belongs_user.type.type ===4 && <span className="author_tag error">{data.belongs_user.type.type_name}</span>}
              {!_.isEmpty(data.belongs_user.type) && data.belongs_user.type.type ===5 && <span className="author_tag processing">{data.belongs_user.type.type_name}</span>}
              {!_.isEmpty(data.belongs_user.tntm_user_info) && data.belongs_user.tntm_user_info.online_status === false && <span className="author_tag purple">{intl.get('userType')}</span>}
              {!_.isEmpty(data.belongs_user.tntm_user_info) && data.belongs_user.tntm_user_info.online_status === true && localStorage.getItem('market_id') === '36' && <span className="author_tag remote">{intl.get('remote')}</span>}
              { data.belongs_user.is_official && <p>{intl.get('official')}</p> }
            </div>
            }
            
            <div className="author_list_item">
              {/* {intl.get("template.topic_hot")} */}
              <span className="hot">{data.view_points_count} {intl.get("viewpoint")}</span>
              <span className="hot">{data.favorite_count} {intl.get("collect")}</span>
              <span className="hot">{data.browse_info_count} {intl.get("onlooker")}</span>
            </div>
          </div>
          {/* <div className="topic_text">
            <span>@{intl.get('official')}：</span>不仅不会消退，反而会持续发烧十年以上。第一，大规模的更先进的雪场无论室内室外无论南方北方的狼，基础建无论南方北方的狼，基础建无论南方北方的狼，基础建
          </div> */}
        </>
      )}
    </div>
  )
}

export default BattleTopic;