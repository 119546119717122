import { createAction, handleActions } from 'redux-actions'


const setCount = createAction('SET_COUNT')
const setLanguage = createAction('SET_LANGUAGE')
const setSelect = createAction('SET_SELECT')
const setIsLogin = createAction('SET_ISLOGIN')


export const appReducer = handleActions({
  [ setCount ]( state, { payload: { count } }){
    return {...state, count}
  },
  [ setLanguage ]( state, { payload: { language } }){
    return {...state, language}
  },
  [ setSelect ]( state, { payload: { select } }){
    return {...state, select}
  },
  [ setIsLogin ]( state, { payload: { islogin } }){
    return {...state, islogin}
  },
},{
  count: 0,
  language:'zh',
  islogin: localStorage.getItem('TOPIC_TOKEN') && localStorage.getItem('TOPIC_TOKEN') !== 'undefined' ? true : false,
  select:{
    class:null,
    topic:null,
    time:null
  }
})
const appActions = {
  setCount,
  setLanguage,
  setSelect,
  setIsLogin
}
export default appActions
