import React, { useEffect, useState } from 'react'
import _ from 'underscore'
import intl from 'react-intl-universal'
import { Ellipsis,Tag } from 'antd-mobile'
import tuijian_title_icon from '@/static/image/tuijian_title_icon.png'
import BasicsIcon from '../BasicsIcon'
import './index.less'

const BasicsTopic = (props)=>{
  const data = props.data || {};
  return (
    <div className="basicsTopic">
      {props.top ? <BasicsIcon /> : null}

      <div className="title">{data.topic_name}</div>
      {props.type === "recommend" && !_.isEmpty(data.view_points_list) && (
        <div className="author">
          <img src={!_.isEmpty(data.view_points_list[0].belongs_user) && data.view_points_list[0].belongs_user.avatar} alt="" />
          {!_.isEmpty(data.view_points_list[0].belongs_user) && data.view_points_list[0].belongs_user.nick_name}
          {!_.isEmpty(data.view_points_list[0].belongs_user.type) && data.view_points_list[0].belongs_user.type.type ===0 && <span className="author_tag success">{data.view_points_list[0].belongs_user.type.type_name}</span>}
          {!_.isEmpty(data.view_points_list[0].belongs_user.type) && data.view_points_list[0].belongs_user.type.type ===1 && <span className="author_tag warn">{data.view_points_list[0].belongs_user.type.type_name}</span>}
          {!_.isEmpty(data.view_points_list[0].belongs_user.type) && data.view_points_list[0].belongs_user.type.type ===4 && <span className="author_tag error">{data.view_points_list[0].belongs_user.type.type_name}</span>}
          {!_.isEmpty(data.view_points_list[0].belongs_user.type) && data.view_points_list[0].belongs_user.type.type ===5 && <span className="author_tag processing">{data.view_points_list[0].belongs_user.type.type_name}</span>}
          {!_.isEmpty(data.view_points_list[0].belongs_user.tntm_user_info) && data.view_points_list[0].belongs_user.tntm_user_info.online_status === false && <span className="author_tag purple">{intl.get('userType')}</span>}
          {!_.isEmpty(data.view_points_list[0].belongs_user.tntm_user_info) && data.view_points_list[0].belongs_user.tntm_user_info.online_status === true && localStorage.getItem('market_id') === '36' && <span className="author_tag remote">{intl.get('remote')}</span>}
          <span>{data.view_points_list[0].time_tran}</span>
        </div>
      )}
      {props.type === "recommend" && !_.isEmpty(data.view_points_list) && (
        <div className="text">
          <Ellipsis
            rows={3}
            content={data.view_points_list[0].content}
          />
        </div>
      )}
      {props.type === "topic" && (
        <>
          <div className="author_list">
            {
              !_.isEmpty(data.belongs_user) && <div className="author_list_item">
              {/* {intl.get("template.preside")} */}
              <img src={data.belongs_user.avatar} alt="" />
              <span>{data.belongs_user.nick_name}</span>
                {!_.isEmpty(data.belongs_user.type) && data.belongs_user.type.type ===0 && <span className="author_tag success">{data.belongs_user.type.type_name}</span>}
                {!_.isEmpty(data.belongs_user.type) && data.belongs_user.type.type ===1 && <span className="author_tag warn">{data.belongs_user.type.type_name}</span>}
                {!_.isEmpty(data.belongs_user.type) && data.belongs_user.type.type ===4 && <span className="author_tag error">{data.belongs_user.type.type_name}</span>}
                {!_.isEmpty(data.belongs_user.type) && data.belongs_user.type.type ===5 && <span className="author_tag processing">{data.belongs_user.type.type_name}</span>}
                {!_.isEmpty(data.belongs_user.tntm_user_info) && data.belongs_user.tntm_user_info.online_status === false && <span className="author_tag purple">{intl.get('userType')}</span>}
                {!_.isEmpty(data.belongs_user.tntm_user_info) && data.belongs_user.tntm_user_info.online_status === true && localStorage.getItem('market_id') === '36' && <span className="author_tag remote">{intl.get('remote')}</span>}
              {
                data.belongs_user.is_official && <p>{intl.get('official')}</p>
              }
            </div>
            }
            <div className="author_list_item">
              {/* {intl.get("template.topic_hot")} */}
              <span className="hot">{data.view_points_count} {intl.get("viewpoint")}</span>
              <span className="hot">{data.favorite_count} {intl.get("collect")}</span>
              <span className="hot">{data.browse_info_count} {intl.get("onlooker")}</span>
            </div>
          </div>
          {
            !_.isEmpty(data.view_points_list) &&  <div className="topic_text">
              <span>{ !_.isEmpty(data.view_points_list[0].belongs_user) && data.view_points_list[0].belongs_user.nick_name}
              {!_.isEmpty(data.view_points_list[0].belongs_user.type) && data.view_points_list[0].belongs_user.type.type ===0 && <span className="author_tag success">{data.view_points_list[0].belongs_user.type.type_name}</span>}
              {!_.isEmpty(data.view_points_list[0].belongs_user.type) && data.view_points_list[0].belongs_user.type.type ===1 && <span className="author_tag warn">{data.view_points_list[0].belongs_user.type.type_name}</span>}
              {!_.isEmpty(data.view_points_list[0].belongs_user.type) && data.view_points_list[0].belongs_user.type.type ===4 && <span className="author_tag error">{data.view_points_list[0].belongs_user.type.type_name}</span>}
              {!_.isEmpty(data.view_points_list[0].belongs_user.type) && data.view_points_list[0].belongs_user.type.type ===5 && <span className="author_tag processing">{data.view_points_list[0].belongs_user.type.type_name}</span>}
              {!_.isEmpty(data.view_points_list[0].belongs_user.tntm_user_info) && data.view_points_list[0].belongs_user.tntm_user_info.online_status === false && <span className="author_tag purple">{intl.get('userType')}</span>}
              {!_.isEmpty(data.view_points_list[0].belongs_user.tntm_user_info) && data.view_points_list[0].belongs_user.tntm_user_info.online_status === true && localStorage.getItem('market_id') === '36' && <span className="author_tag remote">{intl.get('remote')}</span>}
              ：</span>{data.view_points_list[0].content}
            </div>
          }

        </>
      )}
    </div>
  )
}

export default BasicsTopic;
