import React, { useState, useEffect, createRef } from "react"
import intl from "react-intl-universal"
import { Ellipsis } from "antd-mobile"
import pk_03 from '@/static/image/pk_03.png'
import hot from '@/static/image/hot.png'
import "./index.less"

const VoteItem = (props) => {
  const {data} = props;
  return (
    <div className="voteItem">
      <div>
        {
          data.belongs_user && <>
          <img className="head_img" src={data.belongs_user.avatar} alt="" />
          <p className="name">{data.belongs_user.nick_name}</p>
          </>
        }
      </div>
      <p className="desc">
        <span>{intl.get('support')}：</span><Ellipsis direction='middle' content={`${data.belongs_vote.support_name}`} />  
      </p>
    </div>
  )
}


export default VoteItem
