import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import _ from 'underscore'
import { PullToRefresh,InfiniteScroll,DotLoading } from 'antd-mobile';
import { Swiper, SwiperSlide } from 'swiper/react'
import {Pagination, Autoplay} from 'swiper'
import Loading from '@/components/Layouts/Loading'
import Sidebar from '@/components/Sidebar'
import MinaShare from "@/components/MinaShare"
import { isMina } from '@/utils/index'
import { eventService } from "@/service";
import intl from "react-intl-universal"
import {BasicsTopic,BattleTopic, VoteTopic} from '@/components/TopicTemplate'
import create from '@/static/image/create.png'
import wecomTemp from '@/static/image/wecom-temp.jpg'
import wecomTemp2 from '@/static/image/wecom-temp2.jpg'
import { loginUrlRedirect } from '@/utils/auth'
import './index.less'


let params = {
  per_page:10, //每页条数
  page:1, //当前页
  is_recom:true, // 是否是推荐
}

const InfiniteScrollContent = ({ hasMore }) => {
  return (
    <>
      {hasMore ? (
        <>
          <span>Loading</span>
          <DotLoading />
        </>
      ) : (
        <span>--- {intl.get('InfiniteScroll_nodata')} ---</span>
      )}
    </>
  )
}


const Home = (props)=>{
  const [detail,setDetail] = useState(null);
  const [list,setList] = useState([]);
  const [right,setRight] = useState(10);
  const [banner,setBanner] = useState([]);
  const [hasMore,setHasMore] = useState(true);

  useEffect(() => {
    window.scrollTo(0,0)
    getBanner();
    getData();
    let reset = function(){
      let innerWidth = window.innerWidth
      if(innerWidth > 700){
        setRight((innerWidth - 700) / 2)
      }else{
        setRight(10)
      }
    }
    reset()
    window.onresize=reset
  }, []);

  const getBanner = async ()=>{
    let {data,status} =  await eventService.getBanner()
    if(status){
      setBanner(data);
    }
  }

  
  const getData = async ()=>{
    let {data,status} =  await eventService.getTopic(params)
    if(status){
      let arr = list.concat(data.data);
      if(params.page === 1){
        arr = data.data;
      }
      if(arr.length * params.page >= data.total){
        setHasMore(false)
      }
      setList(arr);
      setDetail(data);
    }
  }

  const pageNext =  ()=>{
    params.page = params.page+1;
    return getData()
  } 


  const toDetail = (item)=>{
    props.history.push('/topic/detail/' + item.id)
  }

  const toCreate = ()=>{
    if(!props.islogin){
      loginUrlRedirect()
      return 
    }
    props.history.push('/createTopic')
  }

  return (
    <div className="home">
      {_.isEmpty(detail) ? (
          <Loading />
      ) : (
        <PullToRefresh
          onRefresh={async () => {
            params.page = 1;
            await getData()
          }}
        >
          <div className='banner'>
            <Swiper
              modules={[ Pagination,Autoplay]}
              autoplay
              loop
              pagination={{ clickable: true }}
              slidesPerView={1}
              className='swiper'
             >
               {
                 banner.map(item=>{
                   return <SwiperSlide key={item.id} onClick={()=>{
                      location.href=item.link
                    }}>
                      <div className='swiper_item'>
                        <img src={item.picture} alt="" />
                      </div>
                    </SwiperSlide>
                  })
               }
            </Swiper>
          </div>
          <div className='main_content'>
            {
              list ? list.map((item,index)=>{
                return <div key={index} className='item' onClick={()=>{toDetail(item)}}>
                    {item.type_id === 1 && <BasicsTopic top={index === 0} type='recommend' data={item} />  }
                    {item.type_id === 2 && <BattleTopic top={index === 0} type='recommend' data={item} />  }
                    {item.type_id === 3 && <VoteTopic top={index === 0} type='recommend' data={item} />  }
                  </div>
              })
              :
              null
            }
            <InfiniteScroll loadMore={pageNext} hasMore={hasMore} >
             <InfiniteScrollContent hasMore={hasMore}/>
            </InfiniteScroll>
          </div>
          <Sidebar>
            {/* {
              isMina() &&
              <MinaShare title={intl.get('mina_share.base')}/>
            } */}
            <div className='create' onClick={toCreate}>
              <img src={create} alt="" />
            </div>
          </Sidebar>
        </PullToRefresh>
      )}
    </div>
  )
}



const mapState = state => {
  return {
    islogin:state.app.islogin,
  };
};

export default connect(mapState)(Home)