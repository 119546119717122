import React, { useState,useEffect } from 'react';
import { connect } from "react-redux"
import { Button,Modal, Badge } from 'antd-mobile';
import intl from "react-intl-universal"
import { eventService } from "@/service";
import appActions from "@/store/modules/app";
import Loading from '@/components/Layouts/Loading'
import { loginUrlRedirect } from '@/utils/auth'
import './index.less';



const  Me = (props)=>{
  const [detail,setDetail] = useState(null);
  const [unread_count,setunread_count] = useState(null);
  useEffect(()=>{
    if(props.islogin){
      getData();
      getNotifyCount();
      let polling_Interval = setInterval(()=>getNotifyCount(),10000);
      return ()=>{
        clearInterval(polling_Interval)
      }
    }else{
      setDetail({})
    }
  },[])

  const getData = ()=>{
    eventService.mySpaceUser().then(res=>{
      if(res && res.status){
        setDetail(res.data);
      }
    })
  }

  //轮询系统消息
  const getNotifyCount = ()=>{
    eventService.getNotifyCount().then(res=>{
      if(res && res.status){
        if(res.data.unread_count !== 0){
          setunread_count(res.data.unread_count);
        }
      }
    })
  }

  const tablan = ()=>{
    Modal.show({
      content: intl.get('my.tablan'),
      closeOnAction: true,
      actions: [
        {
          key: 'zh',
          text: intl.get('zh_en'),
          primary: true,
        },
        {
          key: 'en',
          text: intl.get('english'),
          primary: true,
        },
        {
          key: 'cancel',
          text: intl.get('cancel'),
        },
      ],
      onAction:(val)=>{
        if(val.key === 'cancel') return ;
        let {currentLocale} = intl.options;
        if(currentLocale === val.key){
          return 
        }else{
          localStorage.setItem('LOCALE', val.key);
          location.href = location.origin + '/home';
          location.reload(true)
        }
      }
    })
  }

  const outlogin = ()=>{
    eventService.loginOut({
      redirect:location.origin + '/home'
    }).then(res=>{
      if(res && res.status){
        setDetail({})
        localStorage.removeItem('TOPIC_TOKEN');
        props.dispatch(appActions.setIsLogin({islogin:false}));
        location.href = res.data.logoutUrl
      }
    })
   
  }

  return <div className="my">
    {
      !detail ?  <Loading />
      :
      <div className="page flex-col">
        <div className="box1 flex-col">
          <div className="mod2 flex-col" onClick={()=>{
              if(!props.islogin){
                loginUrlRedirect()
                return 
              }
            }}>
            <div className="group4 flex-col">
              <div className="box3 flex-row justify-between">
                <div className="SingleAvatar1 flex-col" >
                  {
                    props.islogin && <img src={detail.avatar} alt="" />
                  }
                </div>
                <span className="word8">{ props.islogin ? detail.nick_name : intl.get('noLogin_text')}</span>
              </div>
            </div>
            <div className="group1 flex-col justify-between">
              <div className="group2 flex-col" />
              <div className="group3 flex-col">
                <div className="main1 flex-row">
                  <div className="main2-0 flex-col">
                    <div className="main2-0 flex-col justify-center" onClick={()=>{
                      if(!props.islogin){
                        loginUrlRedirect()
                        return 
                      }
                      props.history.push('/commuityData?index=0')
                    }}>
                      <span className="word6-0 text-center">{detail.topics_count || 0}</span>
                      <span className="word7-0">{intl.get('my.topic')}</span>
                    </div>
                  </div>
                  <div className="main2-1 flex-col" onClick={()=>{
                      if(!props.islogin){
                        loginUrlRedirect()
                        return 
                      }
                      props.history.push('/commuityData?index=1')
                    }}>
                    <div className="main2-1 flex-col justify-center">
                      <span className="word6-1 text-center">{detail.viewpoints_count || 0}</span>
                      <span className="word7-1">{intl.get('my.viewpoint')}</span>
                    </div>
                  </div>
                  <div className="main2-2 flex-col" onClick={()=>{
                      if(!props.islogin){
                        loginUrlRedirect()
                        return 
                      }
                      props.history.push('/commuityData?index=2')
                    }}>
                    <div className="main2-2 flex-col justify-center">
                      <span className="word6-2 text-center">{detail.comment_count || 0}</span>
                      <span className="word7-2">{intl.get('my.point_detail_text')}</span>
                    </div>
                  </div>
                  <div className="main2-3 flex-col" onClick={()=>{
                      if(!props.islogin){
                        loginUrlRedirect()
                        return 
                      }
                      props.history.push('/commuityData?index=3')
                    }}>
                    <div className="main2-3 flex-col justify-center">
                      <span className="word6-3 text-center">{detail.favorite_count || 0}</span>
                      <span className="word7-3">{intl.get('my.collect')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* {
            !localStorage.getItem('redirect_url') && */}
            <>
            <div className="mod3 flex-row" onClick={()=>{props.history.push('/settingInfo')}}>
              <Badge content={unread_count}>
                <div className="bd1 flex-col"><span className="txt1">{intl.get('my.setting_info')}</span></div>
              </Badge>
                <i className='iconfont'>&#xe62f;</i>
              </div>
              {
                !localStorage.getItem('redirect_url') && props.islogin && <div className="mod3 flex-row" onClick={tablan}>
                <div className="bd3 flex-col"><span className="txt1">{intl.get('my.tablan')}</span></div>
                <i className='iconfont'>&#xe62f;</i>
              </div>
              }
              {
                props.islogin && !localStorage.getItem('redirect_url') && <div className="mod3 flex-row" onClick={outlogin}>
                <div className="bd5 flex-col"><span className="txt1">{intl.get('my.outlogin')}</span></div>
                <i className='iconfont'>&#xe62f;</i>
              </div>
              }
            </>
          {/* } */}
          {/* <div className="mod3 flex-row" onClick={()=>{
              if(!props.islogin){
                loginUrlRedirect()
                return 
              }
              props.history.push('/commuityData')
            }}>
            <div className="bd2 flex-col"><span className="txt1">{intl.get('my.myhome')}</span></div>
            <i className='iconfont'>&#xe62f;</i>
          </div> */}
          
          {/* <div className="mod3 flex-row">
            <div className="bd4 flex-col"><span className="txt1">{intl.get('my.Privacy')}</span></div>
            <i className='iconfont'>&#xe62f;</i>
          </div> */}
          
          
        </div>
      </div>
    }
  </div>

}

const mapState = (state) => {
  return {
    islogin:state.app.islogin
  }
}

export default connect(mapState)(Me);