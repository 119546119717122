import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import { Ellipsis } from 'antd-mobile'
import tuijian_title_icon from '@/static/image/tuijian_title_icon.png'
import './index.less'


const BasicsIcon = ()=>{
  return (
    <div className='title_icon_basics_icon'><img src={tuijian_title_icon} alt="" />{intl.get('template.top')}</div>
  )
}

export default BasicsIcon;