import React, { useEffect, useState } from 'react';
import { object } from 'underscore';
import './index.less'

const Sidebar = (props) => {
  const {children, style} = props
  const [right, setRight] = useState(10);

  const reset = function(){
    let innerWidth = window.innerWidth
    if(innerWidth > 700){
      setRight((innerWidth - 700) / 2)
    }else{
      setRight(10)
    }
  }

  useEffect(() => {
    window.onresize = reset
    reset()
  }, [])

  return(
    <div
      className='sidebar'
      style={style ? Object.assign({}, style, {right: right}): {right: right}}>
      {children}
    </div>
  )
}

export default Sidebar