
/**
 * token 处理
 */

const TOKEN_KEY = `access_token`;

export default {
  get() {
    const token = localStorage.getItem(TOKEN_KEY);
    return token ? JSON.parse(token) : null;
  },
  set(value) {
    return localStorage.setItem(TOKEN_KEY, JSON.stringify(value));
  },
  del() {
    return localStorage.removeItem(TOKEN_KEY)
  },
}

export const loginUrlRedirect = (institution_id)=>{
  let params = '&scope=trade_business_card&response_type=code&identity=2';
  if(institution_id){
    params = params + '&state='+institution_id
  }else{
    params =  params + '&state=0'
  }
  const loginUrl = localStorage.getItem('login_url')
  localStorage.setItem('topic.pathname',location.pathname)
  const redirectUrl = `${encodeURIComponent( !loginUrl ? location.origin : location.href)}${params}`
  const loginLink = loginUrl ? `${loginUrl}?redirect_url=${redirectUrl}` : `${process.env.REACT_APP_LOGIN_PAGE_URL}?client_id=26&redirect_uri=${redirectUrl}`
  console.log('loginLink', loginLink)
  location.href = loginLink
}