import React, { useEffect, useState } from "react"
import _ from "underscore"
import intl from "react-intl-universal"
import { Ellipsis,Button } from "antd-mobile"
import { useHistory } from "react-router-dom"
import "./index.less"

const ViewpointItem = (props) => {
  const data = props.data || {};
  const history = useHistory();

  return (
    <div className="viewpoint" onClick={()=>{
      history.push('topic/detail/' + data.topic_id)
    }}>
      <div className="title">
        <Ellipsis
          rows={3}
          content={data.content}
        />
      </div>
      {
        data.belongs_topic && <div className="topic_text">
        <div className="left">
          <img src={data.belongs_topic.belongs_user.avatar} alt="" />
        </div>
        <div className="right">
          <span>{data.belongs_topic.belongs_user.nick_name}：</span>{data.belongs_topic.topic_name}
        </div>
      </div>
      }
      
      
    </div>
  )
}

export default ViewpointItem
