import React, { useEffect, useState } from "react"
import _ from "underscore"
import intl from "react-intl-universal"
import { Ellipsis,Button } from "antd-mobile"
import { useHistory } from "react-router-dom"
import "./index.less"


const TopicItem = (props) => {
  const data = props.data || {}
  const history = useHistory();
  return (
    <div className="topicItem" onClick={()=>{
      if(data.status ===2){
        history.push('/topic/detail/'+ data.id)
      }
    }}>
      <div className="title">{data.topic_name}</div>
      <div className="topicItem_author_list">
        {
          data.status === 2 &&<><div className="author_list_item">
            {intl.get("template.topic_hot")}
            <span className="hot">
              {data.view_points_count} {intl.get("viewpoint")}
            </span>
            <span className="hot">
              {data.favorite_count} {intl.get("collect")}
            </span>
            <span className="hot">
            {data.browse_info_count} {intl.get("onlooker")}
            </span>
          </div>
          <div className="author_list_item">{data.created_at}</div>
          </>
        }
        {
           data.status === 3 &&  <div className="author_list_item">{intl.get('my.error_text')}：  {data.topic_operation_log && data.topic_operation_log[0].check_info}</div>
        }
        
        
        <div className="topicItem_footer">
          { data.status === 3 && <Button onClick={()=>{
            history.push('/createTopic?id='+ data.id);
          }}> <i className="iconfont">&#xe617;</i> {intl.get('my.resetedit')}</Button>  }
          { data.status === 2 && <p className="success"> <i className="iconfont">&#xe62a;</i> {intl.get('my.success')}</p> }
          { data.status === 1 && <p className="loading"> <i className="iconfont">&#xe619;</i> {intl.get('my.loading')}</p> }
          { data.status === 3 && <p className="loading"> <i className="iconfont">&#xe619;</i> {intl.get('my.error')}</p> }
        </div>
      </div>
    </div>
  )
}

export default TopicItem
