import React, { useEffect, useState } from 'react'
import ppo from 'ppo'
import intl from 'react-intl-universal'
import { renderRoutes } from 'react-router-config'
import routes from './routes'
import { BrowserRouter } from 'react-router-dom'
import Loading from '@/components/Layouts/Loading'
import { ConfigProvider, Toast } from 'antd-mobile'
import appActions from "@/store/modules/app";
import zhCN from 'antd-mobile/es/locales/zh-CN'
import enUS from 'antd-mobile/es/locales/en-US'
import { Provider } from "react-redux"
import configStore from "./store"
import { eventService } from "@/service"
import {isMina, wxPostMessage} from '@/utils/index'
const store = configStore();


// 项目语言包
const locales = {
  "en": require('./locales/en.json'),
  "zh": require('./locales/zh.json'),
}


const App = ()=>{
  const [language, setLanguage] = useState('');
  const [laoding, setLaoding] = useState(true);
  const [tokenLoading,setTokenLoading] = useState(false);
  useEffect(() => {
    if(ppo.getUrlParam('code')){
      Toast.show({
        icon:"loading",
        duration:0
      })
      eventService.login({code:ppo.getUrlParam('code')}).then(res=>{
        Toast.clear()
        if(res && res.status){
          store.dispatch(appActions.setIsLogin({islogin:true}));
          localStorage.setItem('TOPIC_TOKEN',res.data.access_token);
          if(localStorage.getItem('topic.pathname')){
            location.href = location.origin + localStorage.getItem('topic.pathname')
          }else{
            location.href = location.origin + '/home'
          }

        }else{
          Toast.show({
            icon:"fail",
            content:res.message
          })
        }
      })
    }
    let institution_id = ppo.getUrlParam('institution_id');
    if(institution_id && institution_id !== 'null'){
      localStorage.setItem('institution_id',institution_id)
    }else{
      localStorage.setItem('institution_id',0);
    }

    //返回地址是否存在
    if(ppo.getUrlParam('redirect_url')){
      let url = decodeURI(ppo.getUrlParam('redirect_url'));
      localStorage.setItem('redirect_url',url);
    }

    //是否携带登录参数
    const loginUrl = ppo.getUrlParam('login_url')
    loginUrl && localStorage.setItem('login_url', decodeURI(loginUrl));

    //是否存在免登录
    if(ppo.getUrlParam('token')){
      setTokenLoading(true);
      localStorage.removeItem('TOPIC_TOKEN')
      // Toast.show({
      //   icon:"loading",
      //   duration:0
      // })

      let token = decodeURI(ppo.getUrlParam('token'))
      eventService.getToken({
        token:token
      }).then(res=>{
        // Toast.clear()
        if(res.status){
          store.dispatch(appActions.setIsLogin({islogin:true}));
          localStorage.setItem('TOPIC_TOKEN',res.data.access_token);
          localStorage.setItem('market_id',res.data.market_id);
          localStorage.setItem('institution_id',res.data.institution_id)
          setTokenLoading(false);
        }else{
          Toast.show({
            icon:"fail",
            content:res.message
          })
        }
      })
    }


    let locale = ppo.getUrlParam('lang');
    if(!locale){
      locale = localStorage.getItem('LOCALE') || 'zh'
    }
    setLanguage(locale);
      store.dispatch(
        appActions.setLanguage({
          language:locale
        })
      );
    intl.init({
      currentLocale: locale,
      locales,
    }).then(() => {
      document.title = intl.get('title')
      localStorage.setItem('LOCALE', locale);
      setLaoding(false)
      isMina() && wxPostMessage(intl.get('mina_share.base'))
    })
  }, [])


  return (
    <div>
      <Provider store={store}>
        <ConfigProvider locale={language === "en" ? enUS : zhCN}>
        {tokenLoading ? (
            <Loading />
          ) : (
            <BrowserRouter history={routes}>
              {laoding ? <Loading /> : renderRoutes(routes)}
            </BrowserRouter>
          )}
        </ConfigProvider>
      </Provider>
    </div>
  )
}

export default App;
