import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import _ from 'underscore'
import { Ellipsis } from 'antd-mobile'
import toupiao_title_icon from '@/static/image/toupiao_title_icon.png'
import toupiao_commen_icon from '@/static/image/toupiao_commen_icon.png'
import BasicsIcon from '../BasicsIcon'
import './index.less'


const VoteTopic = (props)=>{
  const data = props.data || {}


  const renderContent = (data)=>{
    let comment_title = null;
    data.vote_list.map(item=>{
      let id = data.view_points_list[0].belongs_vote.id;
      if(item.id === id){
        comment_title = item.support_name
      }
    })
    return <div className='comment'>
      <img className='toupiao_commen_icon' src={toupiao_commen_icon} alt="" />
      <div className='author'>
        <img src={data.view_points_list[0].belongs_user.avatar} alt="" /> <span className='name'>{data.view_points_list[0].belongs_user.nick_name}</span>
        {!_.isEmpty(data.view_points_list[0].belongs_user.type) && data.view_points_list[0].belongs_user.type.type ===0 && <span className="author_tag success">{data.view_points_list[0].belongs_user.type.type_name}</span>}
        {!_.isEmpty(data.view_points_list[0].belongs_user.type) && data.view_points_list[0].belongs_user.type.type ===1 && <span className="author_tag warn">{data.view_points_list[0].belongs_user.type.type_name}</span>}
        {!_.isEmpty(data.view_points_list[0].belongs_user.type) && data.view_points_list[0].belongs_user.type.type ===4 && <span className="author_tag error">{data.view_points_list[0].belongs_user.type.type_name}</span>}
        {!_.isEmpty(data.view_points_list[0].belongs_user.type) && data.view_points_list[0].belongs_user.type.type ===5 && <span className="author_tag processing">{data.view_points_list[0].belongs_user.type.type_name}</span>}
        {!_.isEmpty(data.view_points_list[0].belongs_user.tntm_user_info) && data.view_points_list[0].belongs_user.tntm_user_info.online_status === false && <span className="author_tag purple">{intl.get('userType')}</span>}
        {!_.isEmpty(data.view_points_list[0].belongs_user.tntm_user_info) && data.view_points_list[0].belongs_user.tntm_user_info.online_status === true && localStorage.getItem('market_id') === '36' && <span className="author_tag remote">{intl.get('remote')}</span>}
        <span className='time'>{data.view_points_list[0].time_tran}</span>
      </div>
      <div className='comment_title'>
        <Ellipsis
          rows={1}
          direction='middle'
          content={`${intl.get('support')}：${comment_title}`}
        />
      </div>
      <div className='comment_content'>
        <Ellipsis
          rows={2}
          content={data.view_points_list[0].content}
        />
      </div>
    </div>
  }

  return (
    <div className='voteTopic'>
      {
        props.top ? <BasicsIcon /> : <div className='title_icon'><img src={toupiao_title_icon} alt="" />{intl.get('template.vote')}</div>
      }
        <div className="title">{data.topic_name}</div>
        {
          !_.isEmpty(data.vote_list) && <div className="list">
            {
              data.vote_list.map(item=>{
                return (
                  <div className={(!_.isEmpty(data.vote_info) && data.vote_info.topic_vote_id === item.id) ? "item_box active" : "item_box" } key={item.support_name}>
                    <div className='line_bg' style={{width:item.ratio + '%'}}><div></div></div>
                    <div className="item">
                      <span className='text'>{item.support_name}</span>
                      <span className='num'>{item.ratio + '%'}</span>
                    </div>
                  </div>
                )
              })
            }
        </div>
        }
        
        {
          props.type ==='recommend' && !_.isEmpty(data.vote_list) && !_.isEmpty(data.view_points_list) && renderContent(data)
        }
        
        <div className='footer'>{data.person_total} {intl.get('take')} <span>{intl.get('end',{time:data.end_time})}</span> </div>

        {props.type === "topic" && (
        <>
          <div className="author_list">
            {
              !_.isEmpty(data.belongs_user) && <div className="author_list_item">
              {/* {intl.get("template.preside")} */}
              <img src={data.belongs_user.avatar} alt="" />
              <span>{data.belongs_user.nick_name}</span>
              {!_.isEmpty(data.belongs_user.type) && data.belongs_user.type.type ===0 && <span className="author_tag success">{data.belongs_user.type.type_name}</span>}
              {!_.isEmpty(data.belongs_user.type) && data.belongs_user.type.type ===1 && <span className="author_tag warn">{data.belongs_user.type.type_name}</span>}
              {!_.isEmpty(data.belongs_user.type) && data.belongs_user.type.type ===4 && <span className="author_tag error">{data.belongs_user.type.type_name}</span>}
              {!_.isEmpty(data.belongs_user.type) && data.belongs_user.type.type ===5 && <span className="author_tag processing">{data.belongs_user.type.type_name}</span>}
              {!_.isEmpty(data.belongs_user.tntm_user_info) && data.belongs_user.tntm_user_info.online_status === false && <span className="author_tag purple">{intl.get('userType')}</span>}
              {!_.isEmpty(data.belongs_user.tntm_user_info) && data.belongs_user.tntm_user_info.online_status === true && localStorage.getItem('market_id') === '36' && <span className="author_tag remote">{intl.get('remote')}</span>}
              { data.belongs_user.is_official && <p>{intl.get('official')}</p> }
            </div>
            }
            
            <div className="author_list_item">
              {/* {intl.get("template.topic_hot")} */}
              <span className="hot">{data.view_points_count} {intl.get("viewpoint")}</span>
              <span className="hot">{data.favorite_count} {intl.get("collect")}</span>
              <span className="hot">{data.browse_info_count} {intl.get("onlooker")}</span>
            </div>
          </div>
        </>
      )}

    </div>
  )
}

export default VoteTopic;