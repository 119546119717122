import React, { useState, useEffect, createRef } from "react"
import intl from "react-intl-universal"
import { connect } from "react-redux"
import { Ellipsis,Toast,TextArea, Button, Dialog } from "antd-mobile"
import pk_03 from '@/static/image/pk_03.png'
import { eventService } from "@/service"
import _ from "underscore"
import hot from '@/static/image/hot.png'
import PointViewDetailItem from "../PointViewDetailItem"
import "./index.less"

const PointViewItem = (props) => {
  const [data,setData] = useState(props.data);
  const [topic_success,setTopic_success] = useState(false);
  const [textareaValue,setTextareaValue] = useState('')

  useEffect(()=>{
    setData(props.data)
  },[props.data])

  //点赞 踩、取消点赞 踩
  const view_points_listtar =async (operation)=>{
    Toast.show({
      icon:'loading',
      maskClickable:false,
      duration:0
    })
    let {status,message} = await eventService.savecommentStar({
      operation,
      comment_id:data.id
    })
    if(status){
      props.reset()
    }else{
      Toast.clear()
      Toast.show({
        icon:'fail',
        content:message
      })
    }
  }

  //发布评论
  const saveComment = async ()=>{
    if(!textareaValue){
        Toast.show({
          icon:"fail",
          content:intl.get('point_detail_error')
        })
        return false
      }
      Toast.show({
        icon:'loading',
        maskClickable:false,
        duration:0
      })
      let params = {
        content:textareaValue,
        topic_id:data.topic_id,
        viewpoint_id:props.viewpoint_id,
        parent_id:data.id,
        reply_id:data.id,
        receive_uid:data.user_id
      }
      eventService.saveComment(params).then(res=>{
          if(res && res.status){
            props.reset();
            setTextareaValue('');
            setTopic_success(false)
          }else{
            Toast.show({
                icon:"fail",
                content:res.message
              })
          }
      })
  }

  const savecommentDel = ()=>{
    Toast.show({
      icon:'loading',
      maskClickable:false,
      duration:0
    })
    eventService.savecommentDel({comment_id:data.id}).then(res=>{
      if(res && res.status){
        props.reset();
      }else{
        Toast.show({
            icon:"fail",
            content:res.message
          })
      }
    })
  }
  const deletecomment = ()=>{
    Dialog.confirm({
      content: intl.get("delete"),
      confirmText:intl.get("createOk"),
      onConfirm: () => {
        savecommentDel()
      },
    })
  }

  return (
    <div className="pointViewItem">
      <img className="head_img" src={data.user.avatar} alt="" />
      <div className="right">
          <p>
            <span>{data.user.nick_name}
            {!_.isEmpty(data.user.type) && data.user.type.type ===0 && <span className="author_tag success">{data.user.type.type_name}</span>}
            {!_.isEmpty(data.user.type) && data.user.type.type ===1 && <span className="author_tag warn">{data.user.type.type_name}</span>}
            {!_.isEmpty(data.user.type) && data.user.type.type ===4 && <span className="author_tag error">{data.user.type.type_name}</span>}
            {!_.isEmpty(data.user.type) && data.user.type.type ===5 && <span className="author_tag processing">{data.user.type.type_name}</span>}
            {!_.isEmpty(data.user.tntm_user_info) && data.user.tntm_user_info.online_status === false && <span className="author_tag purple">{intl.get('userType')}</span>}
            {!_.isEmpty(data.user.tntm_user_info) && data.user.tntm_user_info.online_status === true && localStorage.getItem('market_id') === '36' && <span className="author_tag remote">{intl.get('remote')}</span>}
             { data.is_author && <a>{intl.get('author')}</a> } </span>
            <li className={data.is_star === true ? "active" : "" }>
                <i className="iconfont" onClick={()=>view_points_listtar('star')}>&#xe602;</i>
                <i className="iconfont" onClick={()=>view_points_listtar('unstar')}>&#xec8c;</i> 
                {data.star_info_count}
            </li>
          </p>
          <div className='comment_content'>
            {/* <Ellipsis
              rows={3}
              content={'3232323'}
            /> */}
            {data.content}
          </div>
          { //是否可以删除
            data.delete_but === 1 && <div onClick={deletecomment} className="footer" style={{marginLeft:'10px'}}>{intl.get('delete')}</div>
          }
          {
             data.delete_but === 2 &&  <div onClick={()=>{setTopic_success(true)}} className="footer">{intl.get('reply')}</div>
          }
          {
            !topic_success ? 
            <div className="comment_detail">
              {
                data.reply.data.length!==0 && data.reply.data.map(item=>{
                return <PointViewDetailItem reset={()=>props.reset()} viewpoint_id={props.viewpoint_id} data={item} key={item.id}></PointViewDetailItem>
                })
              }
            </div>
          :
            <div className="topic_success">
              <div className="textarea">
                <TextArea
                  value={textareaValue}
                  onChange={(val) => {
                    setTextareaValue(val)
                  }}
                  placeholder={intl.get("point_detail_error")}
                  autoSize={{ minRows: 7, maxRows: 7 }}
                />
              </div>
              <div className="topic_success_footer">
                <Button className="nobg button" onClick={() => {setTopic_success(false);setTextareaValue('')}}>
                    <i className="iconfont">&#xe874;</i>
                    {intl.get("cancel")}
                </Button>
                <Button className="button" onClick={saveComment}><i className="iconfont">&#xe617;</i>{intl.get("ok")}</Button>
              </div>
            </div>
          }
          
      </div>
    </div>
  )
}


export default PointViewItem
